import React, { useState } from "react"
import RoundedButton from "../../../rounded-button"
import PlatformIcon from "../../../icons/platform-icon"
import { FleetDayBookModal } from "../../../fleet-day-book-modal"

export const UtilitiesMenu = () => {
    const [isFleetDayBookModalOpen, setIsFleetDayBookModalOpen] = useState<boolean>(false)

    return (
        <>
            <div className="w-4/5 mx-auto mb-4 mt-4">
                <RoundedButton
                    titleClassName="pr-4"
                    icon={<PlatformIcon />}
                    title="Fleet Day Book"
                    onClick={() => {
                        setIsFleetDayBookModalOpen(true)
                    }}
                />
            </div>
            <FleetDayBookModal
                isFleetDayBookModalOpen={isFleetDayBookModalOpen}
                setIsFleetDayBookModalOpen={setIsFleetDayBookModalOpen}
            />
        </>
    )
}
