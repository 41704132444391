import React from "react"
import { FlagIcon } from "../icons/flag-icon"

interface SiteSurveyStatusProps {
  isSiteSurvey: boolean | false
  siteSurveyAssigned: boolean | false
  handleAssignSurveyor: () => void
  handleCompleteSurvey: () => void
  classNames?: string
}

export const SiteSurveyStatus = (props: SiteSurveyStatusProps) => {
    if (!!(props.isSiteSurvey)) {
        return (
            <div className={`flex overflow-hidden bg-white border rounded-lg border-filteringPillsHover`}>
                <div className={`flex items-center justify-center p-5 text-white bg-filteringPillsHover`}>
                    <FlagIcon />
                </div>
                <div className="p-5">
                    <p>{`Site survey is pending${props.siteSurveyAssigned ? "" : ", no surveyor has been assigned"}`}</p>
                    <p>
                        {!props.siteSurveyAssigned && (
                            <>
                                <button 
                                    className="pb-1 text-right underline bg-white sm:text-xs"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        props.handleAssignSurveyor()
                                    }}
                                >
                                    Assign surveyor
                                </button>
                                <label className="sm:text-xs"> or </label>
                            </>
                        )}
                        <button 
                            className="pb-1 text-right underline bg-white sm:text-xs"
                            onClick={(e) => {
                                e.preventDefault()
                                props.handleCompleteSurvey()
                            }}
                        >
                            Complete survey
                        </button>
                    </p>
                </div>
            </div>
        )
    }

    return null
}