import React, { useState } from "react"
import { 
    UserIcon,
    MailIcon,
    PhoneIcon,
    DeviceMobileIcon,
    ClipboardListIcon,
 } from "@heroicons/react/solid"

export type IContactDetailsClipboardProps = {
    firstName: string
    surname: string
    email: string
    phone: string
    mobile: string
}

export const ContactDetailsClipboard = (props: IContactDetailsClipboardProps) => {
    const [isCopied, setIsCopied] = useState<boolean>(false)
    
    const iconContainerClassName = "w-[18px] h-[18px] mr-1"
    const enabledClassName = "text-gray-600 cursor-pointer"
    const disabledClassName = "text-gray-300"

    const name = [props.firstName, props.surname].filter(Boolean).join(" ")
    const everything = [name, props.email, props.phone, props.mobile].filter(Boolean).join("\r\n")

    const renderTitle = (fieldName: string, value: string, isAll: boolean = false) => {
        if (value) {
            return `Copy ${isAll ? 'all details' : `'${value}'`} to clipboard`
        }

        return `Contact does not have a ${fieldName}`
    }

    const copyToClipBoard = (value: string) => {
        if (value) {
            navigator.clipboard.writeText(value)
            setIsCopied(true)
        }
    }

    return (
        <div className="flex flex-row items-center">
            <div className="flex flex-col">
                <span
                    className={iconContainerClassName}
                    onClick={() => copyToClipBoard(name)}
                    title={renderTitle("name", name)}
                >
                    <UserIcon className={name ? enabledClassName : disabledClassName}/>
                </span>
            </div>
            <div className="flex flex-col">
                <span
                    className={iconContainerClassName}
                    onClick={() => copyToClipBoard(props.email)}
                    title={renderTitle("email address", props.email)}
                >
                    <MailIcon className={props.email ? enabledClassName : disabledClassName}/>
                </span>
            </div>
            <div className="flex flex-col">
                <span
                    className={iconContainerClassName}
                    onClick={() => copyToClipBoard(props.phone)}
                    title={renderTitle("telephone number", props.phone)}
                >
                    <PhoneIcon className={props.phone ? enabledClassName : disabledClassName}/>
                </span>
            </div>
            <div className="flex flex-col">
                <span
                    className={iconContainerClassName}
                    onClick={() => copyToClipBoard(props.mobile)}
                    title={renderTitle("mobile number", props.mobile)}
                >
                    <DeviceMobileIcon className={props.mobile ? enabledClassName : disabledClassName}/>
                </span>
            </div>
            <div className="flex flex-col">
                <span
                    className={iconContainerClassName}
                    onClick={() => copyToClipBoard(everything)}
                    title={renderTitle("everything", everything, true)}
                >
                    <ClipboardListIcon className={everything ? enabledClassName : disabledClassName}/>
                </span>
            </div>
            <div className="flex flex-col ml-1">
                <span 
                    className={`text-sm text-gray-600 font-semibold transition ease-out duration-1000${isCopied ? " opacity-100" : " opacity-0" }`}
                    onTransitionEnd={() => setIsCopied(false)}
                >
                    Copied!
                </span>
            </div>
        </div>
    )
}