import React from "react";
import FleetDayBookView from "../../../../fleet-day-book-view";

export default function FleetDayBook(_props: any) {
    return (
        <div 
            className="flex flex-col w-full"
            style={{ height: "calc(100vh - 90px)" }}
        >
            <FleetDayBookView/>
        </div>
    )
}