import React, { useState } from "react"
import { addComma } from "../../../../../utils/add-comma"
import { AlertBanner } from "../../../../alert-banner"
import AlertMessage from "../../../../alert-message"
import { useCustomerContext } from "../store"
import { useResourceStringsNewContracts } from "./new-contracts/use-resource-strings"
import { useMsal } from "@azure/msal-react"
import { NewContractButton } from "../../../../new-contract-button"
import { HireInsuranceStatus } from "../../../../hire-insurance-status"
import { NewSiteSurveyButton } from "../../../../new-site-survey-button"
import { AdditionalTransportChargesModal } from "../../../../additional-transport-charges-modal"
import { addSite, internalGetDefaultAndCustomerAdditionalTransportCharges } from "../../../../../middleware/middleware-layer"
import { LoadAddressListModal } from "../../../../load-address-list-modal"
import { AddAmendAddressModal } from "../../../../add-amend-address-modal"
import { LoadingFeedback } from "../../../../loading-feedback"
import { Modal } from "../../../../modal"
import { Button } from "../../../../button"
import { ContactsListModal } from "../../../../contacts-list-modal"

export const CxCustomerDetail = () => {
  const { customer, isCustomerLoading } = useCustomerContext()
  const { instance, accounts } = useMsal()
  const {
    addressName,
    addressLine1,
    addressLine2,
    addressLine3,
    addressTown,
    addressCounty,
    addressPostcode,
    customerName,
    accountNo,
    mainPhone,
    customerEmailAddress,
    creditLimit,
    balance,
    paymentTerms,
    salesPersonName,
  } = customer || {}

  const initialDefaultAdditionalTransportCharges: Record<string, any> = {
    tier1: 999,
    tier2: 999,
    tier3: 999,
    tier4: 999,
    tier5: 999,
  }

  const [defaultAdditionalTransportCharges, setDefaultAdditionalTransportCharges] =
    useState<Record<string, any>>(initialDefaultAdditionalTransportCharges)

  const [customerAdditionalTransportCharges, setCustomerAdditionalTransportCharges] = useState<Record<string, any>>({})
  const [isAdditionalTransportChargesModalOpen, setIsAdditionalTransportChargesModalOpen] = useState<boolean>(false)
  const [isOpenAddress, setIsOpenAddress] = useState<boolean>(false)
  const [isLoadNewAddressListModalOpen, setIsLoadNewAddressListModalOpen] = useState<boolean>(false)
  const [isAddingAddress, setIsAddingAddress] = useState<boolean>(false)
  const [addAddressResultMessages, setAddAddressResultMessages] = useState<string[]>([])
  const [isAddAddressCompleteModalOpen, setIsAddAddressCompleteModalOpen] = useState<boolean>(false)

  const initialNewAddressState: Record<string, any> = {
    addressName: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressTown: "",
    addressCounty: "",
    addressPostcode: "",
    addressDirections: "",
    addressLat: "",
    addressLong: "",
    addressWhat3Words: "",
  }

  const [addNewAddressValues, setAddNewAddressValues] = useState<
    Record<string, any>
  >(initialNewAddressState)

  const formattedAddress = customer
    ? `${addComma(addressName)} ${addComma(addressLine1)}${addComma(
      addressLine2
    )}${addComma(addressLine3)}${addComma(addressTown)}${addComma(
      addressCounty
    )} ${addressPostcode || ""}`
    : ""

  if (isCustomerLoading) {
    return (
      <div className="flex items-center h-full pl-44">
        <p className="text-xl animate-pulse">Loading...</p>
      </div>
    )
  }

  const { alertOnHold } = useResourceStringsNewContracts()

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  const parseValueAsCurrency = (value: string) => {
    const parsedValue = parseFloat(value)
    if (!isNaN(parsedValue)) {
      return formatter.format(parsedValue)
    }

    return "Not available"
  }

  const onHandleSetAdditionalTransportChargesClick = () => {
    internalGetDefaultAndCustomerAdditionalTransportCharges(
      accounts,
      instance,
      +customer?.customerId,
    )
      .then((result: Record<string, any>) => {
        const charges = result?.data?.internalGetDefaultAndCustomerAdditionalTransportCharges

        if (charges) {
          setDefaultAdditionalTransportCharges({
            ...defaultAdditionalTransportCharges,
            ...charges.default,
          })
          setCustomerAdditionalTransportCharges({
            ...customerAdditionalTransportCharges,
            ...charges.customer,
          })
          setIsAdditionalTransportChargesModalOpen(true)
        }
      })
      .catch(err => {
        console.log(err)
      })


    setIsAdditionalTransportChargesModalOpen(true)
  }

  const handleAddAddressChange = async () => {
    setIsAddingAddress(true)
    await addSite(
      accounts,
      instance,
      customer?.customerId,
      addNewAddressValues,
    )
      .then((result: Record<string, any>) => {
        const { addressId } = result?.data?.internalAddSite

        if (addressId) {
          setAddAddressResultMessages(["The new address was added successfully."])
        } else {
          setAddAddressResultMessages(["The new address could not be added."])
        }
      })
      .catch(err => {
        setAddAddressResultMessages(["The new address could not be added.", err])
        console.log(err)
      })
      .finally(() => {
        setIsAddingAddress(false)
        setIsAddAddressCompleteModalOpen(true)
      })
  }

  const onHandleAddNewAddressClick = () => {
    setAddNewAddressValues(initialNewAddressState)
    setIsOpenAddress(true)
  }

  const onHandleLoadNewAddressListClick = () => {
    setIsLoadNewAddressListModalOpen(true)
  }

  return (
    <div>
      {customer ? (
        <>
          <div className="flex flex-row flex-wrap justify-between items-center">
            <h1 className="font-bold uppercase text-[45px]">{customerName}</h1>
            <AlertMessage mode="danger" messages={[alertOnHold]} show={customer?.isOnHold == "true"} />
          </div>
          <div className="flex flex-row">
            <p className="uppercase text-[22px] mr-8">
              Account No: <span className="font-bold">{accountNo}</span>
            </p>
            <p className="flex-1 uppercase text-[22px]">
              Salesperson: <span className="font-bold">{salesPersonName}</span>
            </p>
            <NewSiteSurveyButton
              accounts={accounts}
              customer={customer}
              instance={instance}
            />
            <NewContractButton
              accounts={accounts}
              customer={customer}
              instance={instance}
            />
          </div>
          <div className="flex flex-row mt-4 gap-x-6">
            <ContactsListModal
              customerId={customer.customerId}
              customerName={customer.customerName}
            />
            <a
              className="flex flex-col text-xs underline cursor-pointer"
              onClick={(e) => {
                e.preventDefault()
                onHandleSetAdditionalTransportChargesClick()
              }}>
              Set category transport charges
            </a>
            <a
              className="flex flex-col text-xs underline cursor-pointer"
              onClick={(e) => {
                e.preventDefault()
                onHandleAddNewAddressClick()
              }}>
              Add new address
            </a>
            <a
              className="flex flex-col text-xs underline cursor-pointer"
              onClick={(e) => {
                e.preventDefault()
                onHandleLoadNewAddressListClick()
              }}>
              Load new address list
            </a>
          </div>
          <hr className="my-8 w-full bg-black h-[2px] opacity-25 b-none" />

          <div className="flex flex-row w-full gap-24">
            <div className="flex flex-col flex-grow max-w-[50%]">
              <h2 className="uppercase text-[27px] font-bold mb-6">
                Contact Details
              </h2>
            </div>
            <div className="flex flex-col">
              <h2 className="uppercase text-[27px] font-bold mb-6">
                Account Details
              </h2>
            </div>
          </div>
          <div className="flex flex-row w-full gap-24">
            <div className="flex flex-col flex-grow max-w-[50%]">
              <h3 className="mb-2 text-xl font-bold uppercase">Address</h3>
              <p className="mb-8">{formattedAddress}</p>
            </div>
            <div className="flex flex-col">
              <h3 className="mb-2 text-xl font-bold uppercase">Payment Terms</h3>
              <p className="mb-8">{paymentTerms}</p>
            </div>
          </div>
          <div className="flex flex-row w-full gap-24">
            <div className="flex flex-col flex-grow max-w-[50%]">
              <h3 className="mb-2 text-xl font-bold uppercase">Telephone No</h3>
              <p className="mb-8">
                <a href={`tel:${mainPhone?.replace(" ", "")}`}>{mainPhone}</a>
              </p>
            </div>
            <div className="flex flex-col">
              <h3 className="mb-2 text-xl font-bold uppercase">Credit Limit</h3>
              <p className="mb-8">{parseValueAsCurrency(creditLimit)}</p>
            </div>
          </div>
          <div className="flex flex-row w-full gap-24">
            <div className="flex flex-col flex-grow max-w-[50%]">
              <h3 className="mb-2 text-xl font-bold uppercase">Email Address</h3>
              <p className="mb-8">
                <a className="underline" href={`mailto:${customerEmailAddress}`}>
                  {customerEmailAddress}
                </a>
              </p>
            </div>
            <div className="flex flex-col">
              <h3 className="mb-2 text-xl font-bold uppercase">A/C Balance</h3>
              <p className="mb-8">{parseValueAsCurrency(balance)}</p>
            </div>
          </div>
        </>
      ) : (
        <h1>No Customer Found</h1>
      )}
      <HireInsuranceStatus
        hasInsurance={customer?.hasInsurance}
        insuranceRenewalDate={customer?.insuranceRenewalDate}
        classNames="mb-8"
      />
      {customer?.alertText && <AlertBanner text={customer?.alertText} />}
      <AdditionalTransportChargesModal
        customer={customer}
        customerAdditionalTransportCharges={customerAdditionalTransportCharges}
        defaultAdditionalTransportCharges={defaultAdditionalTransportCharges}
        isOpen={isAdditionalTransportChargesModalOpen}
        setIsOpen={setIsAdditionalTransportChargesModalOpen}
      />
      <LoadingFeedback
        showSpinner={isAddingAddress}
        loadingText="Adding address..."
        translucentBackground={true}
      />
      <AddAmendAddressModal
        handleDetailChange={handleAddAddressChange}
        isOpen={isOpenAddress}
        setIsOpen={setIsOpenAddress}
        isOpenConfirm={false}
        setIsOpenConfirm={() => { }}
        ctaLabel={{ title: "CREATE NEW ADDRESS", cta: "ADD ADDRESS" }}
        setNewAddressValues={setAddNewAddressValues}
        newAddressValues={addNewAddressValues}
        setCanAmend={() => { }}
        addressKeyName={"siteAddress"}
        onCancelled={() => { }}
      />
      <Modal
        title="Add New Address"
        isOpen={isAddAddressCompleteModalOpen}
        closeModal={() => { }}
      >
        <div>
          {addAddressResultMessages.map((message: string) => (
            <div className="mt-4 mb-2">
              <p className="leading-5">{message}</p>
            </div>
          ))}
          <div>
            <Button
              className="mt-8"
              outline
              onClick={() => { setIsAddAddressCompleteModalOpen(false) }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
      <LoadAddressListModal
        customerId={customer?.customerId}
        isOpen={isLoadNewAddressListModalOpen}
        setIsOpen={setIsLoadNewAddressListModalOpen}
      />
    </div>
  )
}
