import { useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { getFleetDayBook, getFleetDayBookCategories } from "../../middleware/middleware-layer"
import moment from "moment"
import Select from "react-select"
import { LoadingFeedback } from "../loading-feedback"
import DateBoxField from "../date-box-field"

interface IFleetDayBookViewProps {
    initialCategory?:string
}

export const FleetDayBookView = (props: IFleetDayBookViewProps) => {
    const { instance, accounts } = useMsal()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [categoryOptions, setCategoryOptions] = useState<Record<string, any>[]>([])
    const [bookings, setBookings] = useState<Record<string, any>[]>()
    const [nextNDaysDate, setNextNDaysDate] = useState<Date | null>(null)

    const initialBookingSelectionValues = {
        categoryId: 0,
        showSimilar: false,
        depotId: -1,
        nextNDays: 3,
        showAllUnconfirmed: false,
    }

    const [bookingSelection, setBookingSelection] = useState<Record<string, any>>(initialBookingSelectionValues)

    const depotOptions: Record<string, any>[] = [
        { label: "* All Depots *", value: -1},
        { label: "Head Office", value: 4},
        { label: "Rehire", value: 5},
        { label: "Wakefield", value: 1},
    ]

    const tableHeaderClassName = "sticky top-[-1px] bg-gray-200 border p-1"
    const today = moment(new Date(), "DD/MM/YYYY")
    const minimumNextNDaysDate = today.add(1, 'day').toDate()
    const maximumNextNDaysDate = today.add(30, 'day').toDate()

    const handleValueChange = (key: string, value: any) => {
        let selection = {
            ...bookingSelection,
        }
        
        // Handle the date picker separately because we need to update nextNDays
        if (key === "nextNDaysDate") {
            const startDate = moment(new Date(), "DD/MM/YYYY")
            const endDate = moment(value, "DD/MM/YYYY")
            const duration = moment.duration(endDate.diff(startDate))
            selection.nextNDays = Math.round(duration.asDays())
            setNextNDaysDate(value)
        } else {
            selection[key] = value
        }

        // If the number of days has changed, update the datepicker
        if (key === "nextNDays") {
            const date = new Date()
            date.setDate(date.getDate() + value)
            setNextNDaysDate(date)
        }      

        setBookingSelection(selection)
        getBookings(selection)
    }

    const getCategories = async () => {
        return await getFleetDayBookCategories(accounts, instance)
            .then((results: Record<string, any>) => {
                const options = results?.data?.internalGetFleetDayBookCategories?.categories.map((category: any) => {
                    return {
                        label: category.categoryCode,
                        value: category,
                    }
                })
                setCategoryOptions(options)

                if (props.initialCategory) {
                    return options.find((option: any) => option.label === props.initialCategory) || options[0]
                }

                return options[0]
            })
            .catch((error: Record<string, any>) => {
                console.error(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getBookings = async (selection: Record<string, any>) => {
        setIsLoading(true)

        getFleetDayBook(
            accounts, 
            instance, 
            selection.categoryId,
            selection.showSimilar,
            selection.depotId,
            selection.nextNDays,
            selection.showAllUnconfirmed,
        )
            .then((results: Record<string, any>) => {
                setBookings(
                    results?.data?.internalGetFleetDayBook?.bookings
                )
            })
            .catch((error: Record<string, any>) => {
                console.error(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCellHighlight = (highlightId: number) => {

        switch (highlightId) {
            case 1:
                return "bg-green-200"
            case 3:
                return "bg-red-100"
            case 4:
                return "bg-blue-100"
            default:
                return ""
        }
    }

    const resolveDate = (date: string) => {
        const momentDate = moment(date)
            if (momentDate.isValid()) {
              return momentDate.format("DD/MM/YYYY")
            }
            
            return ""
    }

    const renderCheckBox = (value: boolean) => {
        return (
            <div className="flex flex-row w-full justify-center">
                <div className={`flex flex-col w-[17px] h-[17px] border rounded border-gray-400 ${value ? " border-blue-600" : ""} text-center justify-center`}>
                    {value && (
                        <span className="bg-blue-600 text-white">&#10003;</span>
                    )}
                </div>
            </div>
        )
    }

    useEffect(() => {
        async function initialLoad() {
            const initialOption: any = await getCategories()
            const selection = {
                ...bookingSelection,
                categoryId: initialOption.value.categoryId,
            }
            const date = new Date()
            date.setDate(date.getDate() + bookingSelection.nextNDays)
            setNextNDaysDate(date)
            setBookingSelection(selection)
            await getBookings(selection)            
        }
        initialLoad()
    }, [])

    return (
        <>  
            <div className="flex flex-row justify-between items-center w-full mb-4">
                <div className="flex flex-col">
                    <div className="flex flex-row items-center">
                        <label className="text-sm mr-2">Category:</label>
                        <Select
                            id="CategorySelection"
                            value={categoryOptions.find((option) => option.value.categoryId === bookingSelection.categoryId) || null}
                            options={categoryOptions}
                            onChange={(option: any) => {
                                handleValueChange("categoryId", option.value.categoryId)
                            }}
                            placeholder="Category"
                            className="text-sm text-left min-w-32 mr-2"
                            escapeClearsValue={false}
                            backspaceRemovesValue={false}
                            isClearable={false}
                            isSearchable={false}
                        />
                        <label className="text-sm">
                            {categoryOptions.find((option) => option.value.categoryId === bookingSelection.categoryId)?.value.categoryName}    
                        </label>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-row items-center">
                        <input
                            type="checkbox"
                            id="ShowSimilar"
                            checked={bookingSelection.showSimilar}
                            onChange={() => {
                                handleValueChange("showSimilar", !bookingSelection.showSimilar)
                            }}
                            className=" w-[20px] h-[20px] self-center mr-2"
                        />
                        <label className="text-sm">Show Similar</label>
                        <input
                            type="checkbox"
                            id="ShowAllUnconfirmed"
                            checked={bookingSelection.showAllUnconfirmed}
                            onChange={() => {
                                handleValueChange("showAllUnconfirmed", !bookingSelection.showAllUnconfirmed)
                            }}
                            className=" w-[20px] h-[20px] self-center ml-6 mr-2"
                        />
                        <label className="text-sm">Show All Unconfirmed</label>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-row items-center">
                        <label className="text-sm mr-1">Next</label>
                        <input
                            type="number"
                            min={1}
                            max={31}
                            id="NextNDays"
                            value={+bookingSelection.nextNDays || 3}
                            onChange={(e: any) => {
                                handleValueChange("nextNDays", +e.target.value)
                            }}
                            className="border rounded w-12 p-1 mr-1"
                        />
                        <label className="text-sm">Days</label>
                        <DateBoxField
                            date={nextNDaysDate}
                            minDate={minimumNextNDaysDate}
                            maxDate={maximumNextNDaysDate}
                            onChange={(date: Date) => {
                                handleValueChange("nextNDaysDate", date)
                            }}
                            label=""
                            isClearable={false}
                            disableKeyboardInput={true}
                            className="w-24 ml-4"
                        />
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-row items-center">
                        <label className="text-sm mr-2">Depot:</label>
                        <Select
                            id="DepotSelection"
                            value={depotOptions.find((option) => option.value === bookingSelection.depotId)}
                            options={depotOptions}
                            onChange={(option: any) => {
                                handleValueChange("depotId", option.value)
                            }}
                            placeholder="Depot"
                            className="text-sm text-left min-w-36"
                            escapeClearsValue={false}
                            backspaceRemovesValue={false}
                            isClearable={false}
                            isSearchable={false}
                        />
                    </div>
                </div> 
            </div>

            <LoadingFeedback
                showSpinner={isLoading}
                translucentBackground={true}
            />
            
            {!bookings || bookings.length === 0 && (
                <div className="flex flex-row w-full justify-center mt-10">
                    <p>There are no bookings to display</p>
                </div>
            )}
            {bookings && bookings.length > 0 && (
                <>
                    <div className="flex flex-row overflow-y-scroll">
                        <table className="text-xs w-full">
                            <thead>
                                <tr>
                                    <th className={`${tableHeaderClassName} text-left`}>Category</th>
                                    <th className={`${tableHeaderClassName} text-left`}>Description</th>
                                    <th className={`${tableHeaderClassName} text-left`}>Contract No</th>
                                    <th className={`${tableHeaderClassName} text-left`}>Customer</th>
                                    <th className={`${tableHeaderClassName} text-left`}>Site</th>
                                    <th className={`${tableHeaderClassName} text-left`}>On Hire</th>
                                    <th className={`${tableHeaderClassName} text-left`}>Off Hire</th>
                                    <th className={`${tableHeaderClassName} text-center`}>Ret'd</th>
                                    <th className={`${tableHeaderClassName} text-left`}>Status</th>
                                    <th className={`${tableHeaderClassName} text-center`}>Lieu</th>
                                    <th className={`${tableHeaderClassName} text-center`}>Insp.</th>
                                    <th className={`${tableHeaderClassName} text-center`}>B/D</th>
                                    <th className={`${tableHeaderClassName} text-left`}>Fleet No</th>
                                    <th className={`${tableHeaderClassName} text-center`}>Qty</th>
                                    <th className={`${tableHeaderClassName} text-left`}>Depot</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookings?.map((booking, index) => {
                                    
                                    const bgColorClassName = getCellHighlight(booking.highlightId)
                                    const underRepairClassName = booking.underRepair ? " text-red-600" : ""

                                    return (
                                        <tr
                                            key={`booking-${index}`}
                                        >
                                            <td className={`border p-1 text-left ${bgColorClassName}`}>
                                                {booking.categoryCode}
                                            </td>
                                            <td className={`border p-1 text-left ${bgColorClassName}`}>
                                                {booking.categoryName}
                                            </td>
                                            <td className={`border p-1 text-left ${bgColorClassName}`}>
                                                {booking.contractNo}
                                            </td>
                                            <td className={`border p-1 text-left ${bgColorClassName}`}>
                                                {booking.customerName}
                                            </td>
                                            <td className={`border p-1 text-left ${bgColorClassName}`}>
                                                {booking.siteName}
                                            </td>
                                            <td className={`border p-1 text-left ${bgColorClassName}`}>
                                                {resolveDate(booking.onHireDate)}
                                            </td>
                                            <td className={`border p-1 text-left ${bgColorClassName}${booking.highlightUCOHDate ? " bg-red-500": ""}`}>
                                                {resolveDate(booking.offHireDate)}
                                            </td>
                                            <td className={`border p-1 text-left`}>
                                                {renderCheckBox(booking.isReturned)}
                                            </td>
                                            <td className={`border p-1 text-left${underRepairClassName}${booking.statusName === "Collection" ? " bg-blue-100" : ""}`}>
                                                {booking.statusName}
                                            </td>
                                            <td className={`border p-1 text-left`}>
                                                {renderCheckBox(booking.isSubstitution)}
                                            </td>
                                            <td className={`border p-1 text-left`}>
                                                {renderCheckBox(booking.underInspection)}
                                            </td>
                                            <td className={`border p-1 text-left`}>
                                                {renderCheckBox(booking.isBreakdown)}
                                            </td>
                                            <td className={`border p-1 text-left${underRepairClassName}${booking.isCrossHired ? " bg-purple-400" : ""}`}>
                                                {booking.stockNo}
                                            </td>
                                            <td className={`border p-1 text-center ${bgColorClassName}`}>
                                                {booking.qty}
                                            </td>
                                            <td className={`border p-1 text-left ${bgColorClassName}`}>
                                                {booking.depotName}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </>
    )
}

export default FleetDayBookView