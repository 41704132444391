export enum AuthenticatedResourcesStrings {
  INTERNAL_AUTHENTICATED_ERRORHANDLING_404_TITLE = "1c18c49c-457f-569e-b7ee-cdc06cb9625c",
  INTERNAL_AUTHENTICATED_ERRORHANDLING_404_MESSAGE = "f34ebb11-004f-5882-8b9d-942af657850e",
  INTERNAL_AUTHENTICATED_ERRORHANDLING_404_BUTTON = "a0c0f91d-edb7-504d-bbeb-e0e4b106c3b8",
  INTERNAL_AUTHENTICATED_ERRORHANDLING_404_HREF = "c0e674cf-d53b-5e77-9b27-ebdc35f047b1",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_FILTERINGMENU_TITLE = "a7761f55-cef7-5900-bf51-a66bb128ce2a",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_FILTERINGMENU_FILTERINGOPTIONSTITLE = "cbd13331-dfcd-578b-81f7-3c2336e8fcc1",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_FILTERINGMENU_CLEARBUTTONTITLE = "70441d4c-bea0-5fe2-b19b-08eccc05c4ef",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_FILTERINGMENU_CUSTOMERSERCHBUTTONTITLE = "e6bb6c9e-9298-5a24-ad62-4a7e88c51442",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_FILTERINGMENU_CUSTOMERNAME = "fd47ed1a-fbf2-5f6a-b891-184a6bbaf568",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_FILTERINGMENU_SITEADDRESS = "c02b8aed-d5be-5e1c-a94b-31497bfb88bd",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_FILTERINGMENU_ACCOUNTNO = "0620906b-ac0c-5c58-9a59-4655496e6c5c",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_FILTERINGMENU_CONTRACTNO = "34fe06e6-a04b-5462-af26-4026516b704c",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_FILTERINGMENU_TELEPHONENO = "1325376b-5571-5745-8458-931657a3afe3",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_FILTERINGMENU_PURCHASEORDER = "119497bb-0308-55f1-a935-bc5ee1a96df1",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_FILTERINGMENU_COMPANYCONTACT = "eddd923f-a817-585f-a85f-61cfb6656827",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_GRID_TITLE = "00f9f12e-5df4-5923-8e6e-cbe03708b8f4",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_GRID_COLUMNHEADER_CUSTOMERNAME = "7feb20d8-26f4-5528-8b48-e6c926f1ff53",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_GRID_COLUMNHEADER_ACCOUNTNO = "5f90c408-6a83-5be2-b891-74598b690733",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_GRID_COLUMNHEADER_CUSTOMERADDRESS = "dd1721de-8763-570e-85c2-0dcecbbacc10",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_GRID_COLUMNHEADER_TELEPHONENO = "77f42f12-d940-50c0-ba48-adc0f3fd5408",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_GRID_COLUMNHEADER_COMPANYCONTACT = "aff89bf2-338f-5ec4-a207-7fe44b28b7f8",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_GRID_PRESEARCHMESSAGE = "7c6d5c75-9a13-5e51-9d2e-7204f30a2d1c",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_CUSTOMERDETAILURLPREFIX = "dae16fec-8a96-5f22-9079-38e5d7a45242",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_TITLE = "1e7d0407-19b7-516a-8955-5529aca17628",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_SUBHEADING = "a62f206d-41b5-5390-ad3f-7a1393af0b0b",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_REQUESTED = "fe47231a-6271-524a-974a-7408808460d6",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_REQUESTEDPLACEHOLDER = "abc7aa26-35dd-5293-83e1-17bff4dc45f8",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_OFFHIREDATE = "6f3d6c49-ee46-5ce5-bbbb-407b48cf9c3b",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_OFFHIREDATEPLACEHOLDER = "ec635289-68ca-53a6-bfc0-17b151c2e53f",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_TRANSPORTCHARGE = "e1120874-7e82-5640-a1b0-4e0b1ee92d2a",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_TRANSPORTCHARGEPLACEHOLDER = "4beba78c-ac29-5b49-945c-9d0a3d010dcf",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_COLLECTIONDATE = "fea15538-af79-5c70-b109-74125e9b7607",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_COLLECTIONDATEPLACEHOLDER = "76982f1a-f264-5efe-9e07-bbc5db11c2a8",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_DELIVERYSLOT = "8977634c-a856-59ea-a23a-ac0e7a0d6fb3",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_COLLECTIONNOTES = "d233373a-c686-5de6-84ec-777123ffe50e",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_COLLECTIONNOTESPLACEHOLDER = "b157293e-5850-5bae-80cc-191bc0629f44",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_AUTOOFFHIRETITLE = "540a7cea-3a6b-5caa-9efc-5dc3b92595a6",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_AUTOOFFHIREERRORMESSAGE = "55215b82-04ea-5c13-b5e8-b6f1c393ddc0",

  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_SYRINXNOTES = "147e977d-dfe2-5af8-a567-24d4f45f43c7",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_SYRINXNOTESPLACEHOLDER = "4546b618-5d2e-58dd-a6e4-db582d1dc7b9",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_CONTRACTNO = "802d3fe8-98e2-5831-8f99-45ae4a27eae9",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_STATUS = "95ce5026-be11-5793-846a-f7dd33faa461",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_STARTDATE = "75b710c8-f731-5b90-99f5-ed346f9504d9",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_ENDDATE = "9bdd1427-4d5f-59e9-bc4b-9172922b54aa",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_CONTACT = "d90e1e63-36c2-5c3b-b2c7-1544bc828dad",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_PRODUCTORDERNO = "f039fcc4-e8ff-56de-bee0-d2fd686351cb",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_SITE = "4045e9b2-490f-5742-9162-dfd9b972cab6",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_BUTTON_PARTIALOFFHIRE = "31e52529-f1c1-5d53-bd4a-cd55af1793b0",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_BUTTON_OFFHIREORDER = "3b428e8e-8ca5-58b2-8b9c-2e97c4d0e02a",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_OFFHIRE_PRODUCTSONHIRE = "1eb15f5f-5640-5b24-b857-dfb9028b597d",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CONTRACTDETAILS_AMENDBUTTON = "a4f5c7cb-198b-51fa-9009-67d92dff7815",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CONTRACTDETAILS_OFFHIREENTIREBUTTON = "002a3f8f-d4d1-506b-88f4-3f80e2f81708",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CONTRACTDETAILS_OFFHIREPARTIALBUTTON = "4a49f911-3ada-5044-b714-a16c2bb6fbba",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CONTRACTDETAILS_CANCELBUTTON = "a7599077-9a4b-59ac-9685-4fa6bf429ddc",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CONTRACTDETAILS_ALERTTEXT = "2e9ad025-90ee-5de7-bcd2-521dcafd4934",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_TIMEBUTTON1 = "29b07150-08b2-5a30-8dea-d52e4065dadf",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_TIMEBUTTON2 = "9b80aacf-3768-5a2b-a80a-1a478a0e4036",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_TIMEBUTTON3 = "016de9db-3071-5eb5-88c5-63fa3b7b319c",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_TIMEBUTTON4 = "49fd60c8-fe15-555c-96e7-ec757044d8e0",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_SUBMIT = "12e1cc7d-1c1d-5a60-b808-f6823d117e2d",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_SIDE_NAVIGATION = "eddfc0d2-8392-5151-8a52-3a2bf34874bc",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERDETAILS_SIDE_NAVIGATION = "9e81b6d0-a656-5496-a640-89b48765f377",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_SIDE_NAVIGATION = "00c8d301-d697-5e1c-abea-5665b70e296b",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_SIDE_NAVIGATION = "57e9702f-060c-5266-9fe5-b7b6c77cd554",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEW_CONTRACT_NEW_BUTTON = "fea2dca7-3f64-53f5-a51d-9bc5ddc03df8",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEW_CONTRACT_NEW_BUTTON_CREATING = "5174e82c-de9b-59f7-9f72-ec175401aaed",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_NOCONTRACTS = "927963ee-a15f-596e-88cd-1e77dcc39bae",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_GRID_COLUMNHEADER_CONTRACTNO = "2c823f7c-4f89-5de2-89e5-89acb3eb79e4",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_GRID_COLUMNHEADER_STATUS = "1c5b75d4-5136-52b7-8211-2848216d2634",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_GRID_COLUMNHEADER_STARTDATE = "d75f8a40-6f88-5e57-943d-272e0819cc3f",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_GRID_COLUMNHEADER_ENDDATE = "f3918529-aad0-561d-a71a-3f1a174a348f",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_GRID_COLUMNHEADER_PRODUCTORDERNO = "21d95b65-3990-5851-828e-08dec6eca44b",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_GRID_COLUMNHEADER_SITE = "0e6bb59b-f02a-5430-a0f4-14c379198080",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_GRID_COLUMNHEADER_CONTACT = "bd7cdb49-7f04-51da-ab0e-d7e60fb0f686",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_GRID_COLUMNHEADER_STAFFENTERED = "c955ae50-766c-5466-ac12-1c8562fd4db9",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_NOCONTRACTS = "e6eadf10-9ca4-56ab-a7f6-f964a898f27e",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CONTRACTDETAILS_CANCELALERTTEXT = "34e368da-8913-57e8-bc2c-73013faeec33",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CONTRACTDETAILS_CANCELENTIREORDER = "52c4ba4e-ad30-5570-b5cf-d5c0375038f8",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_HIREDETAILS = "c2b9f26c-f912-57d0-85bb-04d91ab1b142",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS = "e1d47d4a-f763-5126-897c-b208fde70dd1",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_ORDERDETAILS = "765e9240-eb48-5bd4-bc99-efbcbdea9147",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_SUMMARY = "4689e99b-96c7-59f7-98ab-38332f3f6ed2",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_ALERTONHOLD = "9bd37dbd-c26b-554b-8268-5acb98e6c82b",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CONTRACTDETAILS_ORDERDETAILS_ENQUIRY_IMMEDIATE = "219c4ffc-8c6c-53ba-abbe-284d67594e02",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CONTRACTDETAILS_ORDERDETAILS_ENQUIRY_COMPLEX = "856dc3cb-3803-5b34-8f39-4b30cbcc7657",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CANCELOFFHIRE_TITLE = "387f1e51-eb17-5e65-86fe-ab02f3945fb4",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CANCELOFFHIRE_SUBTITLE = "1beee67f-c9a5-5c11-9c24-d3ac47c86b4a" ,
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CANCELOFFHIRE_FORMINTRO = "e6d10dff-4dba-5662-b2ea-1556d6d11966",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CANCELOFFHIRE_CANCELLATIONNOTESTITLE = "0e38a129-cc9c-50a3-8be2-3ac66c8eebe0",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CANCELOFFHIRE_REQUESTERTITLE = "840c7711-3b14-58e6-b003-49a07ffb0d33",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CANCELOFFHIRE_NEWOFFHIREDATETITLE = "6541baf0-77a2-5442-8c23-3439a8fb21b0",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CANCELOFFHIRE_AUTOOFFHIRETITLE = "3341a0a9-22ec-5bbd-a00e-b9e7baed40b3",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CANCELOFFHIRE_AUTOOFFHIREERRORMESSAGE = "87ac938d-ab9f-54b1-9cef-009b66535ce1",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CONTRACTDETAILS_ORDERDETAILS_ENQUIRY_FUTURE = "f8920aba-dacc-5b28-8b01-481196b47027",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_HIREDETAILS_EQUIPMENTCODE = "5884bf6e-2313-52b4-a352-611f2d42f074",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_HIREDETAILS_DESCRIPTION = "43f294ab-43b2-5674-b72e-bde98596c24a",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_HIREDETAILS_XH = "771dc40c-a592-5c37-aaf2-f5ca7292317f",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_HIREDETAILS_QUANTITY = "86b0bcc2-71b3-557a-a8db-f9039f2cdc11",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_HIREDETAILS_UNITPRICE = "85242bc3-0c60-591c-b642-1064d619b202",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_HIREDETAILS_HIREPERIOD = "64dc0ff8-31e7-5d66-8dd0-41c2dbc43a4d",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_HIREDETAILS_HIRECHARGE = "ab62e8bd-852e-5a2a-842c-3ec1caf36be9",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_SITEADDRESS = "238edbf0-e6e4-5023-915b-0339ed4db23a",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_SITECONTACT = "c1264910-676b-55e1-8eef-6a5ad070f0b1",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_SITEDIRECTIONSPREVIOUS = "4d471438-aaeb-52a7-ac95-ab58276b5545",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_DEPOT = "c4ab2a37-8595-5d63-8e2c-2a98fcd1aa58",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_DELIVERYDATE = "7bd933a6-55bd-5417-9f5c-34980977088c",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_DELIVERYTIME = "04c32c20-bb77-5459-8ef8-ae215b744e19",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_ONHIREDATE = "1476cab7-701d-5e14-a72b-cfe00ee1b176",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_OFFHIREDATE = "aa1013fe-9f18-5225-94fb-7e600d7d5359",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_DELIVERYCHARGE = "b4e0fd78-1b10-554f-bbc1-c05708ce7081",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_COLLECTIONCHARGE = "75d83061-cb32-5a2c-b7d3-fb8f174338e4",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_SATCHARGE = "2892b87e-658f-582e-ae2a-8a657f5a914f",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_SUNCHARGE = "8c0eba0a-a8ad-5d20-bdaa-3a38abc264bf",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_CALLFIRST = "3a2d04d8-a3b2-50f3-a4ad-d254833d84cd",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_DELIVERYTIME1 = "bd9ae76c-8c99-51fa-ba2b-d5d94e049061",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_DELIVERYTIME2 = "aa9abfa8-8495-504b-aea1-1e013fb877ec",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_DELIVERYTIME3 = "95768765-6b82-517e-bdcb-ff015123be15",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_DELIVERYTIME4 = "d4473f31-4791-5dec-89eb-0be81470e906",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_DELIVERYDETAILS_DELIVERYTIME5 = "22cafd72-e89c-5997-8587-525e5d5335cc",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_FIRMBOOKING = "c3d55694-62d9-5392-bac8-52fa3910d8fe",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_PROVISIONALBOOKING = "3b40c503-039a-5797-ba40-98c9b5ee3020",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_QUOTE = "da65bc53-18c0-5ca6-ae5e-d2a4331ef1c1",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_ORDERDETAILS_AMENDEXISTINGCUSTOMERCONFIRMATION = "c34065dd-d9cb-5244-8c07-cd285c29bcc9",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_ARRANGECOLLECTION_FORMINTRO = "830db6a8-5a84-55d0-9268-fb6da632b576",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CANCELOFFHIRE_DATEPLACEHOLDER = "1e46d22e-69d7-50a3-bba0-54db34253898",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_CANCELOFFHIRE_NOTESPLACEHOLDER = "0e38a129-cc9c-50a3-8be2-3ac66c8eebe0",

  INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_VAT_NO = "7398fe26-6140-5a75-8d7c-a4115e3f3168",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_THANK_YOU = "99bc5779-3361-54c8-9529-8485cdc9d8f3",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_BANK_NAME_ADDRESS = "aeb80093-9a29-50af-b71d-1027e26e55a6",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_BANK_ACCOUNT_NAME = "c2ef1165-8cce-55a7-886c-fb43771aff1b",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_BANK_ACCOUNT_SORT_CODE = "5f15779b-cb6a-58f2-a9e9-6160dad22fb4",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_BANK_ACCOUNT_NUMBER = "86dae85e-7cce-580d-be6b-2855108b9105",
  INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_FOOTER_ADDRESS = "7d0bd5bc-8a8f-5926-93d7-933f089cc079",
}

