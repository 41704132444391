import React, { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react"
import { Modal } from "../modal"
import { Button } from "../button"
import { 
    CheckCircleIcon,
    ClockIcon,
    ExclamationCircleIcon,
    XCircleIcon,
} from "@heroicons/react/solid"
import { addSite, getSitesForCustomerSavedContractsByCustomerId } from "../../middleware/middleware-layer"

enum AddressLoadStatus {
    Pending,
    OK,
    Invalid,
    Warning,
    Loading,
    Loaded,
    Failed,
}

export interface ILoadAddressListProgressModal {
    customerId: string
    addressesData: Record<string, any> | null
    isOpen: boolean
    setIsOpen: (bool: boolean) => void
}

export const LoadAddressListProgressModal = (props: ILoadAddressListProgressModal) => {
    const { accounts, instance } = useMsal()
    const [addresses, setAddresses] = useState<Record<string, any>>()
    const [isLoadButtonEnabled, setIsLoadButtonEnabled] = useState<boolean>(false)
    const [statusMessage, setStatusMessage] = useState<string>("")
    const [isLoadingComplete, setIsLoadingComplete] = useState<boolean>(false)

    const validateData = (customerSites: Record<string, any>) => {
        const validationResults = addresses?.map((address: any) => {
            // First check for a postcode, no point without
            if (address.Postcode === "" || address.Postcode.length < 5) {
                return {
                    ...address,
                    status: AddressLoadStatus.Invalid,
                    statusMessage: "Postcode missing or too short"
                }
            }

            // Site name is mandatory
            if (address.SiteName === "") {
                return {
                    ...address,
                    status: AddressLoadStatus.Invalid,
                    statusMessage: "Site Name is missing"
                }
            }

            // Line 1 is mandatory
            if (address.AddressLine1 === "") {
                return {
                    ...address,
                    status: AddressLoadStatus.Invalid,
                    statusMessage: "Address Line 1 is missing"
                }
            }

            // Check if there is already an address at that postcode
            const postcodeMatch = customerSites?.find((site: any) => 
                site.addressPostcode?.toLowerCase() === address.Postcode.toLowerCase()
            )
            if (postcodeMatch) {
                return {
                    ...address,
                    status: AddressLoadStatus.Warning,
                    statusMessage: [
                        "There is already an address with this postcode:",
                        postcodeMatch.addressName ? `${postcodeMatch.addressName}` : "",
                        postcodeMatch.addressLine1 ? `${postcodeMatch.addressLine1}` : "",
                        postcodeMatch.addressLine2 ? `${postcodeMatch.addressLine2}` : "",
                        postcodeMatch.addressTown ? `${postcodeMatch.addressTown}` : "",
                        postcodeMatch.addressCounty ? `${postcodeMatch.addressCounty}` : "",
                        `${postcodeMatch.addressPostcode}`
                    ]
                        .filter(Boolean)
                        .join("\r\n")
                }
            }

            return {
                ...address,
                status: AddressLoadStatus.OK,
            }
        })

        setAddresses(validationResults)

        const hasInvalidAddress = validationResults.find((address: any) => 
            address.status === AddressLoadStatus.Pending || address.status === AddressLoadStatus.Invalid
        )

        setIsLoadButtonEnabled(hasInvalidAddress ? false : true)
        let resultsByStatus: any = []

        Object.keys(AddressLoadStatus).map((key: any) => {
            if (Number(key)) {
                const count = validationResults.filter((address: any) => AddressLoadStatus[address.status] === AddressLoadStatus[key]).length

                if (count > 0) {
                    resultsByStatus.push({
                        status: AddressLoadStatus[key],
                        count,
                    })
                }
            }
        })

        setStatusMessage(`${validationResults.length} addresses found - ${
            resultsByStatus.map((result: any) => {
                return `${result.count} ${result.status}`
            }).join(", ")
        }`)
    }

    const loadSites = () => {
        const progressMessage = "Loading in progress... "
        setIsLoadButtonEnabled(false)
        setStatusMessage(progressMessage)

        addresses?.map((address: any) => {
            address.status = AddressLoadStatus.Pending
        })

        if (addresses) {
            let loaded = 0;
            let failed = 0;
            addresses.map((address: any) => {
                const site = {
                    addressName: address.SiteName,
                    addressLine1: address.AddressLine1,
                    addressLine2: address.AddressLine2 || null,
                    addressTown: address.TownOrCity || null,
                    addressCounty: address.County || null,
                    addressPostcode: address.Postcode,
                }

                addSite(
                    accounts,
                    instance,
                    props.customerId,
                    site,
                )
                .then((result: Record<string, any>) => {
                    const { addressId } = result?.data?.internalAddSite
                    address.status = addressId ? AddressLoadStatus.Loaded : AddressLoadStatus.Failed
                    addressId ? loaded += 1 : failed += 1
                    setStatusMessage(`${progressMessage} (${loaded} loaded, ${failed} failed)`)

                    if (loaded + failed === addresses.length) {
                        setIsLoadingComplete(true)
                        setStatusMessage(`Loading complete: ${loaded} loaded, ${failed} failed`)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            })
        }
    }

    const resolveStatusColumn = (address: any) => {
        let icon
        let textClassName = ""
        const iconClassName = "size-6"

        switch (address.status) {
            case AddressLoadStatus.OK:
                icon = (
                    <CheckCircleIcon className={iconClassName} />
                )
                textClassName = "text-blue-600"
                break
            case AddressLoadStatus.Invalid:
            case AddressLoadStatus.Failed:
                icon = (
                    <XCircleIcon className={iconClassName} />
                )
                textClassName = "text-red-600 uppercase font-semibold"
                break
            case AddressLoadStatus.Warning:
                icon = (
                    <ExclamationCircleIcon className={iconClassName} />
                )
                textClassName = "text-yellow-600 uppercase font-semibold"
                break
            case AddressLoadStatus.Loaded:
                icon = (
                    <CheckCircleIcon className={iconClassName} />
                )
                textClassName = "text-green-600"
                break
            default:
                icon = (<ClockIcon className={iconClassName} />)
        }

        return (
            <td className="p-2 flex flex-row items-center" title={address.statusMessage}>
                <div className={`flex flex-col mr-2 ${textClassName}`}>
                    {icon}
                </div>
                <span className={`flex flex-col ${textClassName}`}>{AddressLoadStatus[address.status]}</span>
            </td>
        )
    }
    
    const closeModal = () => {
        setIsLoadButtonEnabled(false)
        setStatusMessage("")
        setIsLoadingComplete(false)
        props.setIsOpen(false)
    }

    useEffect(() => {
        setAddresses(
            props.addressesData?.map((address: any) => {
                return {
                    ...address,
                    status: AddressLoadStatus.Pending,
                }
            })
        )
    }, [props.addressesData])

    useEffect(() => {
        if (props.isOpen && addresses) {
            getSitesForCustomerSavedContractsByCustomerId(
                accounts,
                instance,
                props.customerId
              )
                .then((results: any) => {
                  validateData(results?.data?.internalSitesForCustomer)
                })
                .catch(err => {
                  console.log(err)
                })
        }
    }, [props.isOpen])

    return (
        <Modal title="LOAD NEW ADDRESS LIST" isOpen={props.isOpen} closeModal={closeModal} widthClassName="max-w-[75%]">
            <div className="w-full max-h-[90vh] px-2 py-8">
                <div className="mx-8 h-[60vh] overflow-y-auto">
                    {addresses && (
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr className="text-left font-bold">
                                    <th className="p-2 sticky top-0 bg-gray-300">Row</th>
                                    <th className="p-2 sticky top-0 bg-gray-300">Site Name</th>
                                    <th className="p-2 sticky top-0 bg-gray-300">Address Line 1</th>
                                    <th className="p-2 sticky top-0 bg-gray-300">Address Line 2</th>
                                    <th className="p-2 sticky top-0 bg-gray-300">Town Or City</th>
                                    <th className="p-2 sticky top-0 bg-gray-300">County</th>
                                    <th className="p-2 sticky top-0 bg-gray-300">Postcode</th>
                                    <th className="p-2 sticky top-0 bg-gray-300">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {addresses && addresses.map((address: any, index: number) => (
                                    <tr 
                                        key={`contract-log-entry-${index}`}
                                        className="text-base text-left align-top even:bg-gray-100 odd:bg-white leading-5"
                                    >
                                        <td className="p-2">{index + 2}</td>
                                        <td className="p-2">{address.SiteName}</td>
                                        <td className="p-2">{address.AddressLine1}</td>
                                        <td className="p-2">{address.AddressLine2}</td>
                                        <td className="p-2">{address.TownOrCity}</td>
                                        <td className="p-2">{address.County}</td>
                                        <td className="p-2">{address.Postcode}</td>
                                        {resolveStatusColumn(address)}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="flex flex-row w-full mx-8 mt-4">
                    <p className="font-semibold">{statusMessage || "Validating..."}</p>
                </div>
                <div className="flex flex-row gap-x-4 items-center justify-center mt-8">
                    <Button outline onClick={closeModal}>
                        {isLoadingComplete ? "CLOSE" : "CANCEL"}
                    </Button>
                    {!isLoadingComplete && (
                        <Button
                            disabled={!isLoadButtonEnabled}
                            onClick={(e) => {
                                e.preventDefault()
                                loadSites()
                            }}
                        >
                            LOAD
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    )
}