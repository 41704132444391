import React, { useState } from "react"
import Select from "react-select"
import { classNames } from "../../utils/classNames"
import { navigate } from "gatsby"
import Moment from "react-moment"
import CurrencyInput from "react-currency-input-field"
import AsyncSelect from "react-select/async"
import { POSummaryModal } from "../po-summary-modal"
import { IContractDetailItem } from "../../types/interfaces/IContractDetail"
import { savePurchaseOrder, auditSupplierRatesSelection } from "../../middleware/middleware-layer"
import { useMsal } from "@azure/msal-react"
import { ConfirmationModal } from "../confirmation-modal"
import { PreferredSuppliersModal } from "../preferred-suppliers-modal"
import { IPreferredSupplierEquipmentRate } from "../../types/interfaces/IPreferredSupplierEquipmentRate"
import { Modal } from "../modal"
import { Button } from "../button"
import { LoadingFeedback } from "../loading-feedback"
import { IPreferredSupplierRateAuditEntry } from "../../types/interfaces/IPreferredSupplierRateAuditEntry"

export interface ICreatePOFormProps {
  poId?: string
  customerId: string
  contractId: string
  contract: Record<string, any>
  createPOFormValues: Record<string, any>
  handleCreatePOFormChange: (
    key: string,
    value: string | Record<string, any> | boolean
  ) => void
  items: IContractDetailItem[]
  promiseOptions: (inputValue: string) => Promise<Record<string, any>>
  raisedBy?: string
  isSent?: string
  preferredSupplierEquipmentRates: IPreferredSupplierEquipmentRate[]
  consolidatedSupplierEquipmentRate: null | IPreferredSupplierEquipmentRate
  filterState?: any
}

export const CreatePOForm = (props: ICreatePOFormProps) => {
  const { accounts, instance } = useMsal()

  const formatDate = (date: string): Object => {
    return <Moment format="DD-MM-YYYY">{date}</Moment>
  }

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isReturnLinkModalOpen, setIsReturnLinkModalOpen] =
    useState<boolean>(false)
  const [badSubmit, setBadSubmit] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [isConfirmedSend, setIsConfirmedSend] = useState(false)
  const [isPreferredSupplierSelectionOpen, setIsPreferredSupplierSelectionOpen] = useState<boolean>(false)
  const [isPreferredSupplierSelectionWaiting, setIsPreferredSupplierSelectionWaiting] = useState<boolean>(false)
  const [isInvalidSupplierModalOpen, setIsInvalidSupplierModalOpen] = useState(false)
  const [isItemAlreadyOnPOErrorModalOpen, setIsItemAlreadyOnPOErrorModalOpen] = useState<boolean>(false)

  const itemsQuantity = () => {
    const itemsStr = props.items
      .map(item => {
        const stockNumber = item.stockNumber || ""
        return `1 x ${stockNumber}`
      })
      .join(", ")

    let itemList = itemsStr.split(", ")
    let itemCount: Record<string, number> = {}
    let itemListWithQuants: any = []

    if (itemList.length === 1) {
      return itemList[0]
    }

    itemList.forEach(element => {
      itemCount[element] = (itemCount[element] || 0) + 1
    })

    for (var x in Object.keys(itemCount)) {
      let itemStrSplit: string[] = Object.keys(itemCount)[x].split(" ")
      itemListWithQuants.push(
        `${Object.values(itemCount)[x]} x ${
          itemStrSplit[itemStrSplit.length - 1]
        }`
      )
    }

    return itemListWithQuants.join(", ")
  }

  const automaticDeliveryNotes =
    props.items.length > 0
      ? `Please deliver ${itemsQuantity()} ${
          props.contract?.deliveryDate
            ? `on ${
                props.contract.deliveryDate
                  ? new Date(props.contract.deliveryDate).toDateString()
                  : null
              }`
            : ""
        }, pre ${
          props.contract?.promisedDeliveryTime
            ? `${props.contract.promisedDeliveryTime}`
            : ""
        }. 
    ${
      props.contract?.hireStartDate
        ? `Hire to start on ${
            props.contract.hireStartDate
              ? new Date(props.contract.hireStartDate).toDateString()
              : null
          }`
        : ""
    }. ${
      props.contract?.hireEndDate
        ? `OFF HIRE on ${
            props.contract.hireEndDate
              ? new Date(props.contract.hireEndDate).toDateString()
              : null
          }.`
        : "Open hire."
    } Thank you.`
      : ""

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const { poId, contractId, contract, createPOFormValues, items } =
      props || {}
    const { depotId } = contract || {}
    const {
      supplierBranch,
      supplierContact,
      deliveryCharge,
      collectionCharge,
      deliveryNotes,
    } = createPOFormValues || {}
    const { value: branchObj } = supplierBranch || {}
    const {
      supplierId,
      supplierAdrId,
      supplierName,
      supplierAdrName,
      supplierTown,
    } = branchObj || {}
    const { value: contactObj } = supplierContact || {}
    const {
      supplierContactId,
      supplierTitle,
      supplierFirstName,
      supplierSurname,
    } = contactObj || {}
    const poItems = items.map(
      ({ sequenceNo, hirePeriod, fleetNumber, purchaseRate }) => {
        return {
          sequenceNo: +sequenceNo,
          purchaseRate: purchaseRate ? +purchaseRate : purchaseRate,
          fleetNumber,
          hirePeriod: hirePeriod?.value?.hirePeriodValue || "",
        }
      }
    )

    setSubmitting(true)

    try {
      const result = await savePurchaseOrder(
        accounts,
        instance,
        poId ? +poId : null,
        +contractId,
        +depotId,
        +supplierId,
        +supplierAdrId,
        `${supplierName || ""} - ${supplierAdrName || supplierTown || ""}`,
        +supplierContactId,
        `${`${supplierTitle || ""} ` || ""}${supplierFirstName || ""}${
          (supplierFirstName && supplierSurname && " ") || ""
        }${supplierSurname || ""}`,
        +deliveryCharge,
        +collectionCharge,
        showCustomerName,
        `${automaticDeliveryNotes || ""}###${deliveryNotes || ""}`.trim(),
        poItems,
        isConfirmedSend ? isConfirmedSend : false
      )

      if (result.errors && result.errors.length === 1 && result.errors[0].message === "ITEM_ALREADY_ON_PO") {
        setIsItemAlreadyOnPOErrorModalOpen(true)
      } else {
        const poDetails = result.data.internalSavePurchaseOrder

        if (props.preferredSupplierEquipmentRates && poDetails) {
          const selectedSupplier = createPOFormValues.supplier.value
          const auditEntries: any = props.preferredSupplierEquipmentRates.flatMap((rate: any) => {
            return rate.suppliers.flatMap((supplier: any) => {
              return {
                ...supplier,
                contractId: parseInt(contractId),
                poId: poDetails.poId,
                poRefId: poDetails.poRefId,
                timestamp: new Date(),
                raisedBy: accounts[0].name,
                selected: selectedSupplier.isPreferredSupplier && supplier.accountNumber === selectedSupplier.supplierAccountNumber,
                postcode: rate.postcode,
                equipmentCode: rate.equipmentCode,
                chargePeriod: rate.chargePeriod,
              } as IPreferredSupplierRateAuditEntry
            })
          })

          await auditSupplierRatesSelection(
            accounts,
            instance,
            auditEntries
          )
        }

        const state = { ...props.filterState }
        navigate(`/solutions-dashboard/contract/${contractId}`, { state })
      }
    } catch (err) {
      console.log(err)
      setBadSubmit(true)
    } finally {
      setSubmitting(false)
    }
  }

  const { createPOFormValues } = props || {}
  const supplierBranchObj = createPOFormValues?.supplierBranch?.value
  const supplierContactObj = createPOFormValues?.supplierContact?.value
  const { deliveryCharge, collectionCharge, deliveryNotes, showCustomerName } =
    createPOFormValues || {}

  const { items } = props || {}
  const poItemsValid =
    items.every(
      ({ sequenceNo, hirePeriod, fleetNumber, purchaseRate }) =>
        !!sequenceNo &&
        !!purchaseRate &&
        hirePeriod?.value?.hirePeriodValue &&
        !!fleetNumber
    ) && items.length > 0

  const submitDisabled =
    !props.contractId ||
    !props.contract?.depotId ||
    !supplierBranchObj?.supplierId ||
    !supplierBranchObj?.supplierAdrId ||
    !supplierContactObj?.supplierContactId ||
    !supplierContactObj?.supplierEmail ||
    !deliveryCharge ||
    !collectionCharge ||
    !poItemsValid ||
    submitting

  const preferredSuppliersDisabled = props.items?.length === 0

  const supplierContactEmailMissing =
    supplierBranchObj?.supplierAdrId && !supplierContactObj?.supplierEmail

  const handlePreferredSupplierSelected = async (supplier: any) => {
    const ratesWithSupplier = props.preferredSupplierEquipmentRates.filter(rate => {
      return rate.suppliers.find(preferredSupplier => {
        return preferredSupplier.accountNumber === supplier.accountNumber
      })
    })

    if (ratesWithSupplier.length === props.preferredSupplierEquipmentRates.length) {
      // The supplier has rates for all the items on the PO - yay.
      setIsPreferredSupplierSelectionWaiting(true)
      setIsPreferredSupplierSelectionOpen(false)
      props.promiseOptions(supplier.accountNumber)
        .then(result => {
          const supplierDetails = result[0]
          supplierDetails.value.isPreferredSupplier = true
          supplierDetails.value.transport = supplier.transport
          supplierDetails.value.reducedTransportPossible = supplier.reducedTransportPossible
          props.handleCreatePOFormChange("supplier", supplierDetails)
        })
        .finally(() => {
          setIsPreferredSupplierSelectionWaiting(false)
        })
    } else {
      // The supplier does not have rates for one or more of the items on the PO - boo.
      setIsInvalidSupplierModalOpen(true)
    }
  }

  const transportVariance = (transportType: string, transportCost: string) => {
    const parsedTransportCost = parseFloat(transportCost)
    if (!isNaN(parsedTransportCost)) {
      if (props.contract && props.contract.additionalItems?.length > 0) {
        const contractTransport = props.contract.additionalItems.find((additionalItem: Record<string, any>) => {
          return additionalItem.stockNumber === transportType
        })

        if (contractTransport) {
          const variance = parseFloat(contractTransport.chargeAmount) - parsedTransportCost
          const className = variance >= 0 ? 'text-green-600' : 'text-red-600'
          return (
            <span className={`text-center ${className}`}>{`£${variance < 0 ? (variance * -1).toFixed(2) : variance.toFixed(2)}`}</span>
          )
        }
      }
    }
  }

  return (
    <>
      {props.isSent === "Y" && (
        <p className="w-full py-1 ml-5 text-2xl font-bold text-horizonred">
          PO SENT TO PARTNER
        </p>
      )}

      <form className="ml-5" onSubmit={handleSubmit}>
        <div>
          <h2 className="text-2xl font-bold uppercase">
            Purchase Order Details
          </h2>
          <p className="text-lg">
            Are you sure you'd like to {props.poId ? "edit the" : "create a"}{" "}
            purchase order with these items? Please confirm you'd like to
            proceed with the {props.poId ? "edit" : "create"} purchase order
            process and provide any supporting notes.
          </p>
          <p className="mt-5 text-lg">An '*' indicates a required field.</p>
        </div>
        <div className="">
          <h3 className="my-5 text-lg font-bold">Raised By</h3>
          <p>{props.raisedBy || ""}</p>

          <h3 className="my-5 text-lg font-bold">Partner*</h3>
          <div className="flex">
            <AsyncSelect
              id="partner"
              className="w-1/2 border-gray-300 rounded-md shadow-sm sm:text-sm"
              placeholder={"Partner Branch*"}
              value={createPOFormValues?.supplier}
              cacheOptions
              defaultOptions
              loadOptions={props.promiseOptions}
              onChange={(value: Record<string, any>) => {
                props.handleCreatePOFormChange("supplier", value)
              }}
            />
            {props.contract.sitePostCode && props.preferredSupplierEquipmentRates && typeof(props.poId) === 'undefined' && (
              <button
                className={classNames(
                  preferredSuppliersDisabled
                    ? "cursor-not-allowed"
                    : "hover:bg-horizonhover",
                  "ml-6 px-6 py-2 text-sm text-white uppercase border-transparent rounded-full bg-horizonred hover:bg-filteringPillsHover hover:text-white"
                )}
                disabled={preferredSuppliersDisabled || submitting}
                onClick={(e) => {
                  e.preventDefault()
                  setIsPreferredSupplierSelectionOpen(true)
                }}
              >
                Partner Selection
              </button>
            )}
          </div>

          <div className="mb-5">
            <h3 className="mt-5 mb-0 text-lg font-bold">Partner Branch*</h3>
          </div>
          <Select
            id={"partnerBranch"}
            value={props.createPOFormValues?.supplierBranch}
            placeholder={"Partner Branch*"}
            isClearable
            className="w-1/2 border-gray-300 rounded-md shadow-sm sm:text-sm"
            onChange={branch =>
              props.handleCreatePOFormChange("supplierBranch", branch)
            }
            options={
              props.createPOFormValues?.supplier?.value?.branches?.map(
                (item: any) => {
                  return {
                    label: `${item.supplierAdrName || item.supplierTown || ""}`,
                    value: item,
                  }
                }
              ) || []
            }
          />

          <div className={supplierContactEmailMissing ? "mb-3" : "mb-5"}>
            <h3 className="mt-5 mb-0 text-lg font-bold">Partner Contact*</h3>
            {supplierContactEmailMissing && (
              <p className="text-contractStatusRed">
                Please select partner contact with email address.
              </p>
            )}
          </div>
          <Select
            id={"partnerContact"}
            value={props.createPOFormValues?.supplierContact}
            placeholder={"Partner Contact*"}
            isClearable
            className="w-1/2 border-gray-300 rounded-md shadow-sm sm:text-sm"
            onChange={contact =>
              props.handleCreatePOFormChange("supplierContact", contact)
            }
            options={
              props.createPOFormValues?.supplierBranch?.value?.contacts?.map(
                (item: any) => {
                  return {
                    label: `${item.supplierFirstName || ""} ${
                      item?.supplierSurname || ""
                    } - ${
                      item?.supplierEmail || item?.supplierMobileNumber || ""
                    }`,
                    value: item,
                  }
                }
              ) || []
            }
          />

          <h3 className="my-5 text-lg font-bold">Site Contact</h3>
          <p>{props.contract?.siteContactName}</p>

          <h3 className="my-5 text-lg font-bold">Site Directions</h3>
          <p>
            {props.contract?.siteDirections
              ? props.contract?.siteDirections
              : "N/A"}
          </p>

          <h3 className="my-5 text-lg font-bold">Delivery Date</h3>
          <p>{formatDate(props.contract?.deliveryDate)}</p>

          <h3 className="my-5 text-lg font-bold">Hire Start Date</h3>
          <p>{formatDate(props.contract?.hireStartDate)}</p>

          <div className="flex flex-row w-1/2">
            <div className="flex flex-col flex-grow">
              <h3 className="my-5 text-lg font-bold">Delivery Charge*</h3>
            </div>
            <div className="flex flex-col w-1/5 items-center">
              <label className="my-5 text-lg font-bold">
                £ +/-
              </label>
            </div>
          </div>
          
          <div className="flex flex-row w-1/2">
            <div className="flex flex-col flex-grow">
              <CurrencyInput
                id="deliveryCharge"
                name="deliveryCharge"
                placeholder="Delivery Charge*"
                prefix="£"
                value={deliveryCharge}
                decimalsLimit={2}
                onValueChange={(value = "") => {
                  props.handleCreatePOFormChange("deliveryCharge", value)
                }}
                allowNegativeValue={false}
                className="relative py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div className="flex flex-col w-1/5 items-center">
              <span className="flex flex-col flex-grow justify-center items-center w-full font-bold">
                {transportVariance("_DELIVERY", deliveryCharge)}
              </span>
            </div>
          </div>
          {createPOFormValues.supplier?.value?.reducedTransportPossible == true && (
            <p className="text-sm mt-1 ml-3 text-indigo-500">Reduced delivery charge may be available</p>
          )}

          <div className="flex flex-row w-1/2">
            <div className="flex flex-col flex-grow">
              <h3 className="my-5 text-lg font-bold">Collection Charge*</h3>
            </div>
            <div className="flex flex-col w-1/5 items-center">
              <label className="my-5 text-lg font-bold">
                £ +/-
              </label>
            </div>
          </div>

          <div className="flex flex-row w-1/2">
            <div className="flex flex-col flex-grow">
            <CurrencyInput
              id="collectionCharge"
              name="collectionCharge"
              placeholder="Collection Charge*"
              prefix="£"
              value={collectionCharge}
              decimalsLimit={2}
              onValueChange={(value = "") => {
                props.handleCreatePOFormChange("collectionCharge", value)
              }}
              allowNegativeValue={false}
              className="relative py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            </div>
            <div className="flex flex-col w-1/5 items-center">
              <span className="flex flex-col flex-grow justify-center items-center w-full font-bold">
                {transportVariance("_COLLECTION", collectionCharge)}
              </span>
            </div>
          </div>
          {createPOFormValues.supplier?.value?.reducedTransportPossible == true && (
            <p className="text-sm mt-1 ml-3 text-indigo-500">Reduced collection charge may be available</p>
          )}

          <p className="flex items-center my-5 text-lg font-bold">
            Show Customer Name on Order?
            <input
              type="checkbox"
              className="mx-2 w-[19px] h-[19px]"
              checked={showCustomerName}
              onChange={() => {
                props.handleCreatePOFormChange("showCustomerName", !showCustomerName)
              }}
            />
          </p>
          <h3 className="my-5 text-lg font-bold">Contract Delivery Notes</h3>
          <p className="uppercase">{props.contract?.deliveryNotes}</p>
          <h3 className="my-5 text-lg font-bold">PO Delivery Notes</h3>
          <p className="uppercase">{automaticDeliveryNotes}</p>
          <textarea
            placeholder={"Additional notes"}
            className="relative py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default h-[38px] sm:text-sm w-1/2"
            value={deliveryNotes || ""}
            onChange={(value: any) =>
              props.handleCreatePOFormChange(
                "deliveryNotes",
                value.target.value
              )
            }
          />

          <div className="inline-flex justify-between w-full mt-4 mb-8 sm:border-l sm:border-transparent">
            <div className="flex flex-col justify-center">
              <button
                className="text-xs text-right underline"
                onClick={e => {
                  e.preventDefault()
                  setIsReturnLinkModalOpen(true)
                }}
              >
                Return to order details
              </button>
            </div>

            <div className="flex justify-center">
              <div className="ml-2">
                <button
                  type="submit"
                  className={classNames(
                    submitDisabled
                      ? "cursor-not-allowed"
                      : " hover:bg-horizonhover",
                    "px-6 py-2 text-sm uppercase rounded-full border border-gray-500 hover:bg-filteringPillsHover hover:text-white",
                    submitting ? "animate-pulse" : ""
                  )}
                  disabled={submitDisabled || submitting}
                >
                  {submitting ? "Saving..." : "Save"}
                </button>
              </div>

              <div className="ml-2">
                <button
                  type="submit"
                  className={classNames(
                    submitDisabled
                      ? "cursor-not-allowed"
                      : " hover:bg-horizonhover",
                    "px-6 py-2 text-sm text-white uppercase border-transparent rounded-full bg-horizonred hover:bg-filteringPillsHover hover:text-white"
                  )}
                  disabled={submitDisabled || submitting}
                  onClick={e => {
                    e.preventDefault()
                    setModalOpen(true)
                  }}
                >
                  {"Save & Send"}
                </button>
              </div>
            </div>
          </div>
          {badSubmit && (
            <p className="w-full py-1 text-2xl font-bold text-horizonred">
              Issue creating a purchase order
            </p>
          )}
        </div>
      </form>
      <POSummaryModal
        isOpen={isModalOpen}
        setIsOpen={setModalOpen}
        POFormValues={props.createPOFormValues}
        autoNotes={automaticDeliveryNotes}
        contract={props.contract}
        items={props.items}
        submitDisabled={submitDisabled}
        submitting={submitting}
        handleSubmit={handleSubmit}
        setIsConfirmedSend={setIsConfirmedSend}
      />
      <ConfirmationModal
        title="Return to Order Details"
        isOpen={isReturnLinkModalOpen}
        descriptionTexts={[
          "Are you sure you want to return to the order details page?",
          "The changes you made on this page will not be saved.",
        ]}
        confirmationBtnText="Yes"
        handleConfirm={async () => {
          await navigate(`/solutions-dashboard/contract/${props.contractId}`)
        }}
        cancelBtnText="Stay here"
        closeModal={() => {
          setIsReturnLinkModalOpen(false)
        }}
      />
      <PreferredSuppliersModal
        isOpen={isPreferredSupplierSelectionOpen}
        handlePreferredSupplierSelected={handlePreferredSupplierSelected}
        closeModal={() => {
          setIsPreferredSupplierSelectionOpen(false)
        }}
        items={props.items}
        preferredSupplierEquipmentRates={props.preferredSupplierEquipmentRates}
        consolidatedSupplierEquipmentRate={props.consolidatedSupplierEquipmentRate}
      />
      {isPreferredSupplierSelectionWaiting && (
        <LoadingFeedback
          showSpinner={true}
          translucentBackground={true}
        />
      )}
      {isInvalidSupplierModalOpen && (
        <Modal title="Invalid Selection" isOpen={true} closeModal={() => {}}>
          <div>
            <div className="mb-4">
              <p className="leading-5">The selected supplier does not have hire rates for all items on the purchase order. Please select a different supplier, or remove the relevant items.</p>
            </div>
            <div>
              <Button outline className="mr-3" onClick={() => {setIsInvalidSupplierModalOpen(false)}}>
                OK
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <Modal 
        title="Item(s) Already On Purchase Order" 
        isOpen={isItemAlreadyOnPOErrorModalOpen} 
        closeModal={() => navigate(`/solutions-dashboard/contract/${props.contractId}`)}>
          <div>
            <div className="my-4">
              <p className="leading-5">One or more items have already been added to a purchase order.</p>
              <p className="leading-5">Please reload the contract and check.</p>
            </div>
            <div>
              <Button 
                className="mt-4"
                onClick={() => navigate(`/solutions-dashboard/contract/${props.contractId}`)}
              >
                RELOAD
              </Button>
            </div>
          </div>
        </Modal>
    </>
  )
}
