import React from "react"
import { Modal } from "../modal"
import FleetDayBookView from "../fleet-day-book-view"

interface IFleetDayBookModalProps {
    isFleetDayBookModalOpen: boolean
    setIsFleetDayBookModalOpen: (value: boolean) => void
    initialCategory?:string
}

export const FleetDayBookModal = (props: IFleetDayBookModalProps) => {
    return (
        <Modal
            isOpen={props.isFleetDayBookModalOpen}
            closeModal={() => {
                props.setIsFleetDayBookModalOpen(false)
            }}
            widthClassName="w-full max-w-[90vw]"
            children={(
                <>
                <div className="flex flex-col w-full mt-4 p-4" style={{ height: "calc(-8rem + 90vh)" }}>
                    <FleetDayBookView 
                        initialCategory={props.initialCategory}
                    />
                </div>
                <div className="flex flex-row justify-center">
                    <button
                    onClick={e => {
                        e.preventDefault()
                        props.setIsFleetDayBookModalOpen(false)
                    }}
                    className="px-8 py-2 mt-6 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                    >
                        CLOSE
                    </button>
                </div>
                </>
            )}
            title="Fleet Day Book"
        />
    )
}