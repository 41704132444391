import React from "react"
import moment from "moment"
import { IInvoice, IInvoiceItem } from "../../types/interfaces/IInvoice"
import {
    Document,
    Font,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
} from '@react-pdf/renderer'
import { horizomPlatformsLogo } from '../base64-images'
import _ from "lodash"
import FontArimoRegular from "../../../src/fonts/arimo-regular.ttf"
import FontArimoBold from "../../../src/fonts/arimo-bold.ttf"
import FontArimoBoldItalic from "../../../src/fonts/arimo-bold-italic.ttf"
import { getResourceStringByid } from "../../utils/resource-string-helper"
import { AuthenticatedResourcesStrings } from "../../types/enums/authenticated-resource-strings"

interface IInvoiceProps {
    invoice: IInvoice
    title: string
    customer: any
    resourceStrings: any
}

Font.register({
    family: 'Arimo',
    fonts: [
        {
            src: FontArimoRegular,
        },
        {
            src: FontArimoBold,
            fontWeight: 'bold',
        },
        {
            src: FontArimoBoldItalic,
            fontWeight: 'bold',
            fontStyle: 'italic',
        },
    ],
})

const styles = StyleSheet.create({
    body: {
        width: '450px',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    divider: {
        borderTop: '1px',
        borderStyle: 'solid',
        borderColor: '#555555',
        width: '100%',
    },
    dividerThick: {
        borderTop: '2px',
        borderStyle: 'solid',
        borderColor: '#555555',
        width: '100%',
    },
    halfContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
    },
    alignRightContainer: {
        alignItems: 'flex-end',
    },
    alignVerticalCenterContainer: {
        justifyContent: 'center',
    },
    spacer: {
        marginTop: '10px',
    },
    spacerSmall: {
        marginTop: '5px',
    },
    spacerLarge: {
        marginTop: '20px',
    },
    spacerExtraLarge: {
        marginTop: '30px',
    },
    text: {
        fontFamily: 'Arimo',
        fontSize: '8px',
        lineHeight: 1.2,
        color: '#555555',
        marginBottom: '1px',
        padding: 0,
    },
    textSmall: {
        fontSize: '7px',
    },
    textMedium: {
        fontSize: '12px',
    },
    textLarge: {
        fontSize: '16px',
    },
    textBold: {
        fontWeight: 'bold',
    },
    textItalic: {
        fontStyle: 'italic',
    },
    textUpperCase: {
        textTransform: 'uppercase',
    },
    textCenter: {
        textAlign: 'center',
    },
    textRight: {
        textAlign: 'right',
    },
    table: {
        width: '100%',
    },
    tableBorder: {
        width: '100%',
        borderTop: '1px',
        borderLeft: '1px',
        borderStyle: 'solid',
        borderColor: '#555555',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
    },
    tableRowBorder: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
        borderLeft: '1px',
        borderStyle: 'solid',
        borderColor: '#555555',
    },
    tableCell: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
    },
    tableCellBorder: {
        borderRight: '1px',
        borderBottom: '1px',
        borderStyle: 'solid',
        borderColor: '#555555',
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
    },
    tableCellBorders: {
        borderLeft: '1px',
        borderRight: '1px',
        borderTop: '1px',
        borderStyle: 'solid',
        borderColor: '#555555',
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
    },
    tableCellBorderBottom: {
        borderBottom: '1px',
    },
    tableCellQuarter: {
        width: '25%',
    },
    tableCellHalf: {
        width: '50%',
    },
    tableCellText: {
        fontFamily: 'Arimo',
        fontSize: '8px',
        color: '#555555',
        margin: '0 6px 1px',
    },
    tableCellTextLarge: {
        fontFamily: 'Arimo',
        fontSize: '10px',
        color: '#555555',
        margin: '0 6px 1px',
    },
    tableCellTitle: {
        width: '30%',
    },
    tableCellContent: {
        width: '70%',
    },
    tableCellLineDate: {
        padding: '2px 1px 1px',
        width: '12%',
    },
    tableCellLineValue: {
        padding: '2px 1px 1px',
        width: '16%',
    },
    tableCellLineVAT: {
        padding: '2px 1px 1px',
        width: '18%',
    },
    tableCellLineDescription: {
        padding: '2px 1px 1px',
        width: '42%',
    },
    imageHorizonPlatformsLogo: {
        width: '50px',
        height: 'auto',
        marginLeft: '30px',
    },
})

export const InvoicePDF = (props: IInvoiceProps) => {
    const currencyFormatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });

    const resolveCurrency = (value: any) => {
        let parsedValue = parseFloat(value)

        if (!isNaN(parsedValue)) {
            // Credit notes will have a negative value, but are shown as a positive
            if (parsedValue < 0) {
                parsedValue = parsedValue * -1
            }

            return currencyFormatter.format(parsedValue)
        }

        return ""
    }

    const invoiceType = props.invoice.header.invoiceType === "CREDIT" ? "Credit Note" : "Invoice"

    return (
        <Document
            title={props.title}
            author="Horizon Platforms"
            subject={"Copy invoice"}
        >
            <Page size="A4">
                <View style={styles.body}>
                    <View style={[styles.container, styles.spacerExtraLarge]}>
                        <View style={styles.halfContainer}>
                            <Text style={[styles.text, styles.textLarge, styles.textBold, styles.textUpperCase]}>{props.title}</Text>
                        </View>
                        <View style={[styles.halfContainer, styles.alignRightContainer, styles.alignVerticalCenterContainer]}>
                            <Text style={styles.textMedium}>
                                {`${invoiceType} No: ${props.invoice.header.invoiceNo}`}
                            </Text>
                        </View>
                        <View style={styles.spacer}></View>
                    </View>
                    <View style={styles.container}>
                        <View style={styles.tableBorder}>
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCellBorder, styles.tableCellHalf]}>
                                    <View style={styles.spacerSmall}></View>
                                    <Text style={styles.tableCellTextLarge}>{props.customer.customerName}</Text>
                                    {
                                        _.uniq([
                                            props.customer.customerName,
                                            props.customer.addressLine1,
                                            props.customer.addressLine2,
                                            props.customer.addressLine3,
                                            props.customer.addressTown,
                                            props.customer.addressCounty,
                                            props.customer.addressPostcode,
                                        ])
                                            .filter(Boolean)
                                            .map((item: string) => {
                                                return (
                                                    <Text style={styles.tableCellText}>{item}</Text>
                                                )
                                            })
                                    }
                                    <View style={styles.spacer}></View>
                                    <Text style={[styles.tableCellText, styles.textRight]}>{props.customer.accountNo}</Text>
                                    <View style={styles.spacerSmall}></View>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellHalf]}>
                                    <View style={styles.spacerSmall}></View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>Site:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            {_.uniq([
                                                props.invoice.header.siteName,
                                                props.invoice.header.siteLine1,
                                                props.invoice.header.siteLine2,
                                                props.invoice.header.siteLine3,
                                                props.invoice.header.siteTown,
                                                props.invoice.header.siteCounty,
                                                props.invoice.header.sitePostcode,
                                            ])
                                                .filter(Boolean)
                                                .map((item: string) => {
                                                    return (
                                                        <Text style={styles.tableCellText}>{item}</Text>
                                                    )
                                                })}
                                        </View>
                                    </View>
                                    <View style={styles.spacerSmall}></View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>Invoice Date:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            <Text style={styles.tableCellText}>{moment(props.invoice.header.invoiceDate).format("DD/MM/YYYY")}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.spacerSmall}></View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>PO No:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            <Text style={styles.tableCellText}>{props.invoice.header.customerRef}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.spacerSmall}></View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellTitle}>
                                            <Text style={[styles.tableCellText, styles.textBold]}>Contract No:</Text>
                                        </View>
                                        <View style={styles.tableCellContent}>
                                            <Text style={styles.tableCellText}>{props.invoice.header.contractId}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.spacerSmall}></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.container}>
                        <View style={styles.table}>
                            <View style={styles.tableRowBorder}>
                                <View style={[styles.tableCellBorder, styles.tableCellLineDate]}>
                                    <Text style={[styles.tableCellText, styles.textBold]}>From</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineDate]}>
                                    <Text style={[styles.tableCellText, styles.textBold]}>To</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineDescription]}>
                                    <Text style={[styles.tableCellText, styles.textBold]}>Description</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineValue]}>
                                    <Text style={[styles.tableCellText, styles.textRight, styles.textBold]}>Value</Text>
                                </View>
                                <View style={[styles.tableCellBorder, styles.tableCellLineVAT]}>
                                    <Text style={[styles.tableCellText, styles.textRight, styles.textBold]}>VAT</Text>
                                </View>
                            </View>
                            {props.invoice.items.map((item: IInvoiceItem) => (
                                <View style={styles.tableRow}>
                                    <View style={[styles.tableCell, styles.tableCellLineDate]}>
                                        {item.dateFrom && (
                                            <Text style={styles.tableCellText}>{moment(item.dateFrom).format("DD/MM/YYYY")}</Text>
                                        )}
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellLineDate]}>
                                        {item.dateTo && (
                                            <Text style={styles.tableCellText}>{moment(item.dateTo).format("DD/MM/YYYY")}</Text>
                                        )}
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellLineDescription]}>
                                        <Text style={styles.tableCellText}>{item.invoiceDetails}</Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellLineValue]}>
                                        <Text style={[styles.tableCellText, styles.textRight]}>{resolveCurrency(item.lineAmount)}</Text>
                                    </View>
                                    <View style={[styles.tableCell, styles.tableCellLineVAT]}>
                                        <Text style={[styles.tableCellText, styles.textRight]}>{`${resolveCurrency(item.vatAmount)} @ ${item.vatRate}%`}</Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                    </View>
                    {props.invoice.header.invoiceNotes && (
                        <>
                            <View style={styles.spacer}></View>
                            <View style={styles.container}>
                                <Text style={[styles.fullWidth, styles.text, styles.textCenter]}>{props.invoice.header.invoiceNotes}</Text>
                            </View>
                        </>
                    )}
                    <View style={styles.spacer}></View>
                    <View style={styles.container}>
                        <Text style={[styles.fullWidth, styles.text, styles.textMedium, styles.textCenter, styles.textBold, styles.textItalic, styles.textUpperCase]}>
                            {`** Copy ${invoiceType} **`}
                        </Text>
                    </View>
                    <View style={styles.spacerLarge}></View>
                    <View style={styles.spacerLarge}></View>
                    <View style={styles.container}>
                        <Text style={[styles.text, styles.textBold]}>
                            VAT No.&nbsp;
                            {getResourceStringByid(
                                props.resourceStrings,
                                AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_VAT_NO
                            )}
                        </Text>
                    </View>
                    <View style={styles.dividerThick}></View>
                    <View style={styles.spacer}></View>
                    <View style={styles.container}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCell, styles.tableCellQuarter]}>
                                    <Image style={styles.imageHorizonPlatformsLogo} src={horizomPlatformsLogo}></Image>
                                </View>
                                <View style={[styles.tableCell, styles.tableCellHalf]}>
                                    {props.invoice.header.invoiceType !== "CREDIT" && (
                                        <>
                                            <Text style={[styles.text, styles.textSmall, styles.textBold, styles.textUpperCase]}>
                                                {getResourceStringByid(
                                                    props.resourceStrings,
                                                    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_THANK_YOU
                                                )}
                                            </Text>
                                            <Text style={[styles.text, styles.textSmall]}>
                                                Bank - &nbsp;
                                                {getResourceStringByid(
                                                    props.resourceStrings,
                                                    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_BANK_NAME_ADDRESS
                                                )}
                                            </Text>
                                            <Text style={[styles.text, styles.textSmall]}>
                                                Account Name - &nbsp;
                                                {getResourceStringByid(
                                                    props.resourceStrings,
                                                    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_BANK_ACCOUNT_NAME
                                                )}
                                            </Text>
                                            <Text style={[styles.text, styles.textSmall]}>
                                                Sort Code - &nbsp;
                                                {getResourceStringByid(
                                                    props.resourceStrings,
                                                    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_BANK_ACCOUNT_SORT_CODE
                                                )}
                                            </Text>
                                            <Text style={[styles.text, styles.textSmall]}>
                                                Account No - &nbsp;
                                                {getResourceStringByid(
                                                    props.resourceStrings,
                                                    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_BANK_ACCOUNT_NUMBER
                                                )}
                                            </Text>
                                        </>
                                    )}
                                </View>
                                <View style={[styles.tableCell, styles.tableCellQuarter]}>
                                    <View style={styles.table}>
                                        <View style={styles.tableRow}>
                                            <View style={[styles.tableCell, styles.tableCellHalf]}>
                                                <Text style={styles.tableCellText}>Sub-Total</Text>
                                            </View>
                                            <View style={[styles.tableCell, styles.tableCellHalf, styles.tableCellBorders]}>
                                                <Text style={[styles.tableCellText, styles.textRight]}>{resolveCurrency(props.invoice.header.lineTotal)}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <View style={[styles.tableCell, styles.tableCellHalf]}>
                                                <Text style={styles.tableCellText}>VAT</Text>
                                            </View>
                                            <View style={[styles.tableCell, styles.tableCellHalf, styles.tableCellBorders]}>
                                                <Text style={[styles.tableCellText, styles.textRight]}>{resolveCurrency(props.invoice.header.vatTotal)}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <View style={[styles.tableCell, styles.tableCellHalf]}>
                                                <Text style={[styles.tableCellText, styles.textUpperCase]}>Total</Text>
                                            </View>
                                            <View style={[styles.tableCell, styles.tableCellHalf, styles.tableCellBorders, styles.tableCellBorderBottom]}>
                                                <Text style={[styles.tableCellText, styles.textRight]}>{resolveCurrency(props.invoice.header.invoiceTotal)}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.spacer}></View>
                    <View style={styles.divider}></View>
                    <View style={styles.spacer}></View>
                    <View style={styles.container}>
                        <Text style={[styles.fullWidth, styles.text, styles.textSmall, styles.textCenter]}>
                            {getResourceStringByid(
                                props.resourceStrings,
                                AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_INVOICE_FOOTER_ADDRESS
                            )}
                        </Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}