import React from "react"

const InvoicesIcon = () => {
    return (
        // <svg 
        //     className="h-[40px] w-[40px]"
        //     viewBox="-6.4 -6.4 76.80 76.80" 
        //     xmlns="http://www.w3.org/2000/svg" 
        //     strokeWidth="2" 
        //     stroke="#3A3A3A" 
        //     fill="none">
        //         <g id="SVGRepo_iconCarrier">
        //             <circle cx="32" cy="32" r="38" />
        //             <path d="M41.72,45.18H23.3a.14.14,0,0,1-.11-.23c1.26-1.35,7.28-8.25,5-14.46-2.57-6.92-.91-17.91,11.52-12.8" strokeLinecap="round"/>
        //             <line x1="20.39" y1="30.67" x2="37.58" y2="30.67" strokeLinecap="round"/>
        //         </g>
        //     </svg>

        <svg xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 48 48"
            className="h-[40px] w-[40px]"
        >
            <g id="White_outline_-_Transparent_BG_-_Discs" data-name="White outline - Transparent BG - Discs">
                <g id="Background">
                    <path 
                        d="M24,1A23,23,0,1,1,1,24,23,23,0,0,1,24,1m0-1A24,24,0,1,0,48,24,24,24,0,0,0,24,0Z" 
                        fill="#3A3A3A"
                    />
                </g>
            </g>
            <g 
                id="Fills_-_to_output_-_White" 
                data-name="Fills - to output - White"
            >
                <path 
                    d="M33,32.5H15A2.5,2.5,0,0,1,12.5,30V18A2.5,2.5,0,0,1,15,15.5H33A2.5,2.5,0,0,1,35.5,18V30A2.5,2.5,0,0,1,33,32.5Zm-18-16A1.5,1.5,0,0,0,13.5,18V30A1.5,1.5,0,0,0,15,31.5H33A1.5,1.5,0,0,0,34.5,30V18A1.5,1.5,0,0,0,33,16.5Z" 
                    fill="#3A3A3A"
                />
                <path 
                    d="M35,22.5H13a.5.5,0,0,1,0-1H35a.5.5,0,0,1,0,1Z" 
                    fill="#3A3A3A"
                />
                <path 
                    d="M31,28.5a1,1,0,1,1,1-1A1,1,0,0,1,31,28.5ZM31,27v.5h0Z"
                    fill="#3A3A3A"
                />
                <path 
                    d="M28,28.5a1,1,0,1,1,1-1A1,1,0,0,1,28,28.5ZM28,27v.5h0Z" 
                    fill="#3A3A3A"
                />
                <path 
                    d="M22.5,28h-6a.5.5,0,0,1,0-1h6a.5.5,0,0,1,0,1Z" 
                    fill="#3A3A3A"
                />
            </g>
        </svg>
    )
}

export default InvoicesIcon