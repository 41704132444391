import React, { ReactChildren, useEffect } from "react"
import { useMsal } from "@azure/msal-react"
import { getCustomersByCustomerId } from "../../../../../middleware/middleware-layer"
import GridSystem from "../../../../tailwind-grid/grid"
import ColSpanTwo from "../../../../tailwind-grid/col-span-two"
import ColSpanTen from "../../../../tailwind-grid/col-span-ten"
import RoundedButton from "../../../../rounded-button"
import RoundedMagnificationGlassIcon from "../../../../icons/rounded-magnifation-glass-icon"
import TowerBuildingsIcon from "../../../../icons/tower-buildings-icon"
import ScissorLiftIcon from "../../../../icons/scissor-lift-icon"
import { CxCustomerDetail } from "./cx-customer-detail"
import { useCustomerContext } from "../store"
import { useResourceStringsSideNavigation } from "./use-resource-strings"
import { resourceStringExtractor } from "../../../../../utils/resource-string-extractor"
import StatisticsIcon from "../../../../icons/statistics-icon"
import RatesIcon from "../../../../icons/rates-icon"
import PlatformIcon from "../../../../icons/platform-icon"
import InvoicesIcon from "../../../../icons/invoices-icon"

export interface CustomerDetailProps {
  customerId: string
  children: ReactChildren
}

export const CustomerDetail = ({ customerId, children }: any) => {
  const showDetailPage = window.location.pathname.split("/").length === 4
  const { instance, accounts } = useMsal()
  const { setCustomer, setCustomerLoading } = useCustomerContext()
  const r = resourceStringExtractor("AUTHENTICATED_CXDASHBOARD")
  const {
    customerDetailsItem,
    outOnHireItem,
  } = useResourceStringsSideNavigation()

  useEffect(() => {
    if (customerId) {
      setCustomerLoading(true)
      getCustomersByCustomerId(accounts, instance, customerId)
        .then((results: Record<string, any>) => {
          setCustomer(
            results?.data?.internalGetCustomersByCustomerId?.customers[0]
          )
          setCustomerLoading(false)
        })
        .catch((error: Record<string, any>) => {
          console.error(error)
          setCustomerLoading(false)
        })
    }
  }, [customerId])

  return (
    <GridSystem>
      <ColSpanTwo>
        <div className="md:sticky top-3 flex flex-wrap justify-items-start 2xl:space-y-3">
          <div className="m-2 2xl:flex-grow">
            <RoundedButton
              icon={<RoundedMagnificationGlassIcon />}
              title="Search"
              url="/cx-dashboard"
            />
          </div>
          <div className="m-2 2xl:flex-grow">
            <RoundedButton
              icon={<PlatformIcon />}
              title="Fleet Day Book"
              url={`/cx-dashboard/customer/${customerId}/fleet-day-book`}
            />
          </div>
          <div className="m-2 2xl:flex-grow">
            <RoundedButton
              icon={<TowerBuildingsIcon />}
              title={customerDetailsItem}
              url={`/cx-dashboard/customer/${customerId}`}
            />
          </div>
          <div className="m-2 2xl:flex-grow">
            <RoundedButton
              icon={<InvoicesIcon />}
              title="Invoices"
              url={`/cx-dashboard/customer/${customerId}/invoices`}
            />
          </div>
          <div className="m-2 2xl:flex-grow">
            <RoundedButton
              icon={<RatesIcon />}
              title="Hire Rates"
              url={`/cx-dashboard/customer/${customerId}/hire-rates`}
            />
          </div>
          <div className="m-2 2xl:flex-grow">
            <RoundedButton
              icon={<StatisticsIcon />}
              title="Hire Statistics"
              url={`/cx-dashboard/customer/${customerId}/hire-statistics`}
            />
          </div>
          <div className="m-2 2xl:flex-grow">
            <RoundedButton
              icon={<ScissorLiftIcon />}
              title={outOnHireItem}
              url={`/cx-dashboard/customer/${customerId}/contracts/out-on-hire`}
            />
          </div>
          <div className="m-2 2xl:flex-grow">
            <RoundedButton
              icon={<ScissorLiftIcon />}
              title={r("ALLCONTRACTS_SIDE_NAVIGATION")}
              url={`/cx-dashboard/customer/${customerId}/contracts/all-contracts`}
            />
          </div>
        </div>
      </ColSpanTwo>
      <ColSpanTen>
        {showDetailPage ? <CxCustomerDetail /> : <>{children}</>}
      </ColSpanTen>
    </GridSystem>
  )
}
