import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import Papa from "papaparse"
import { Modal } from "../modal"
import { LoadAddressListProgressModal } from "../load-address-list-progress-modal"

export interface ILoadAddressListModal {
    customerId: string
    isOpen: boolean
    setIsOpen: (bool: boolean) => void
}

export const LoadAddressListModal = (props: ILoadAddressListModal) => {
    const [fileToLoad, setFileToLoad] = useState<File | null>(null)
    const [fileData, setFileData] = useState<Record<string, any> | null>(null)
    const [fileValidationErrorMessage, setFileValidationErrorMessage] = useState<string>("")
    const [isContinueButtonEnabled, setIsContinueButtonEnabled] = useState(false)
    const [isLoadNewAddressListProgressModalOpen, setIsLoadNewAddressListProgressModalOpen] = useState<boolean>(false)

    const requiredHeaders = [
        "SiteName",
        "AddressLine1",
        "AddressLine2",
        "TownOrCity",
        "County",
        "Postcode",
        "SiteDirections",
    ]

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop: (files) => {
            parseAndValidateFile(files[0])
        },
        multiple: false,
        accept: {
            "application/csv": [".csv"],
        },
    });
      
    const parseAndValidateFile = (file:File) => {
        try {
            // Parse the file and store it
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                transformHeader: function(header) {
                    return header.replace(/\s/g, "")
                },
                complete: (results) => {
                    if (results.errors.length > 0) {
                        setFileValidationErrorMessage("There were errors when trying to validate the file.")
                    }
                    if ((results.meta.fields?.length || 0) > 0) {
                        if (JSON.stringify(results.meta.fields) === JSON.stringify(requiredHeaders)) {
                            setFileData(results.data)
                            setFileToLoad(file)
                            setIsContinueButtonEnabled(true)
                            return
                        } else {
                            setFileValidationErrorMessage("One or more column headings were missing or incorrect.")
                        }

                        setFileData(null)
                        setFileToLoad(null)
                        setIsContinueButtonEnabled(false)
                    }
                },
            })
        } catch (e) {
            console.log(e)
        }
    }

    const clearAcceptedFiles = () => {
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
    }

    const closeModal = () => {
        clearAcceptedFiles()
        setFileData(null)
        setFileToLoad(null)
        setIsContinueButtonEnabled(false)
        setFileValidationErrorMessage("")
        setIsContinueButtonEnabled(false)
        props.setIsOpen(false)
    }

    return (
        <>
            <Modal title="LOAD NEW ADDRESS LIST" isOpen={props.isOpen} closeModal={closeModal}>
                <p className="m-4 leading-5">The file must be CSV and in the required format.</p>
                <a className="underline" href="/addresslist.csv" download>
                    Download an empty template
                </a>
                <form>
                    <div className="flex flex-col justify-center w-11/12 mx-auto">
                        <div className="w-full py-14 my-6 border rounded-lg border-dashed cursor-pointer" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Drag and drop the document here or click to browse</p>
                        </div>
                    </div>
                    {fileToLoad && (
                        <div className="flex flex-col justify-center w-11/12 mx-auto mb-6">
                            <p className="text-left font-semibold">File to load: {fileToLoad.name}</p>
                        </div>
                    )}
                    {fileValidationErrorMessage && (
                        <div className="flex flex-col justify-center w-4/5 mx-auto mb-6">
                            <p className="text-left font-semibold text-red-600">Validation failed: {fileValidationErrorMessage}</p>
                        </div>
                    )}
                    <div className="flex flex-row gap-2 mx-4">
                        <div className="flex flex-1">
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    closeModal()
                                }}
                                className="w-full px-4 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                            >
                                CANCEL
                            </button>
                        </div>
                        <div className="flex flex-1">
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    setIsLoadNewAddressListProgressModalOpen(true)
                                    props.setIsOpen(false)
                                }}
                                disabled={!isContinueButtonEnabled}
                                className={isContinueButtonEnabled 
                                    ? "w-full px-4 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm cursor-pointer"
                                    : "w-full px-4 py-2 bg-white border border-gray-200 text-gray-200 rounded-full sm:text-sm"
                                }
                            >
                                CONTINUE
                            </button>
                        </div>
                    </div>
                </form>
            </Modal>
            <LoadAddressListProgressModal
                addressesData={fileData}
                customerId={props.customerId}
                isOpen={isLoadNewAddressListProgressModalOpen}
                setIsOpen={(value) => {
                    setIsLoadNewAddressListProgressModalOpen(value)
                    if (!value) {
                        closeModal()
                    }
                }}
            />
        </>
    )
}