import React from "react"

const PlatformIcon = () => {
    return (
        <svg 
            className="h-[40px] w-[40px]"
            viewBox="-6.4 -6.4 76.80 76.80" 
            xmlns="http://www.w3.org/2000/svg" 
            strokeWidth="2" 
            stroke="#3A3A3A" 
            fill="none"
        >
            <g id="White_outline_-_Transparent_BG_-_Discs">
                <circle cx="32" cy="32" r="38" />
                
            </g>
            <g transform="scale(2)" transform-origin="15 15" strokeWidth="0.7" stroke="#000000">
                <path strokeWidth=".5" d="M30.43,19.5H17.57a2.57,2.57,0,0,1,0-5.14H30.43a2.57,2.57,0,0,1,0,5.14ZM17.57,15.36a1.57,1.57,0,0,0,0,3.14H30.43a1.57,1.57,0,0,0,0-3.14Z" />
                <path strokeWidth=".5" d="M30.43,33.64A2.57,2.57,0,1,1,33,31.07,2.57,2.57,0,0,1,30.43,33.64Zm0-4.14A1.57,1.57,0,1,0,32,31.07,1.57,1.57,0,0,0,30.43,29.5Z" />
                <path strokeWidth=".5" d="M17.57,33.64a2.57,2.57,0,1,1,2.57-2.57A2.57,2.57,0,0,1,17.57,33.64Zm0-4.14a1.57,1.57,0,1,0,1.57,1.57A1.57,1.57,0,0,0,17.57,29.5Z" />
                <path strokeWidth=".5" d="M28.68,30.45a.52.52,0,0,1-.21,0L17.36,25.24a.5.5,0,0,1-.29-.46.5.5,0,0,1,.3-.45l13-5.79a.5.5,0,0,1,.41.92L18.78,24.79,28.9,29.5a.5.5,0,0,1-.22.95Z" />
                <path strokeWidth=".5" d="M19.33,30.49a.49.49,0,0,1-.45-.29.5.5,0,0,1,.24-.67l10.21-4.74L17.56,19.46a.5.5,0,0,1,.41-.92l12.76,5.79a.5.5,0,0,1,.3.45.5.5,0,0,1-.29.46l-11.2,5.2A.5.5,0,0,1,19.33,30.49Z" />
                <path strokeWidth=".5" d="M28.36,31.57H19.64a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h8.72a.51.51,0,0,1,.5.5A.5.5,0,0,1,28.36,31.57Z" />
            </g>
        </svg>
    )
}

export default PlatformIcon